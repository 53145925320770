import React from "react";
import PropTypes from "prop-types";
import shortId from "shortid";
import DOMPurify from "dompurify";
import {MEDIA_ENDPOINT} from "../../constant";

const ProductBenefits = props => {
    ProductBenefits.propTypes = {
        benefits: PropTypes.array.isRequired
    }
    const sanitize = DOMPurify.sanitize;
    return (
        <table className="benefit-outer">
            <tbody>
            {props.benefits.map(benefit => {
                const encodedQueryPart = encodeURIComponent(`${benefit.iconText} - ${benefit.featureText}`);
                // const iconURL = `${MEDIA_ENDPOINT}/media/resource/32x32/${benefit.icon}/${encodedQueryPart}.jpg`;
                const iconURL = `${benefit.iconUrl}` + `?io=transform:fit,height:32,width:32`;
                const featureRow = [];
                const standardRow = [];
                for (let i = 0; i < benefit.featureValue; i++) {
                    featureRow.push(<td key={shortId.generate()}/>);
                }
                for (let i = 0; i < benefit.standardValue; i++) {
                    standardRow.push(<td key={shortId.generate()}/>);
                }
                return (
                    <React.Fragment key={benefit.icon}>
                        <tr className="benefit-line">
                            <td className="benefit-icon" rowSpan="4">
                                <img src={iconURL} alt="benefit_icon" />
                            </td>
                            <td className="benefit-icontext" rowSpan="4">
                                {/*TODO remove possible HTML tags from API*/}
                                <span dangerouslySetInnerHTML={{__html: sanitize(benefit.iconText)}} />
                            </td>
                            <td className="benefit-featuretext" colSpan="12">
                                {benefit.featureText ? benefit.featureText :
                                <span>&nbsp;</span>}
                            </td>
                        </tr>
                        <tr className="benefit-featurevalue">
                            {featureRow}
                        </tr>
                        <tr className="benefit-standardvalue">
                            {standardRow}
                        </tr>
                        <tr>
                            <td className="benefit-standardtext" colSpan="12">
                                {benefit.standardText ? benefit.standardText :
                                <span>&nbsp;</span>}
                            </td>
                        </tr>
                    </React.Fragment>
                );
            })}
            </tbody>
        </table>
    );
}

export default ProductBenefits;
