import {DEFAULT_CONFIG} from "../constant";
import "../data/de_DE.json"

/** Funktion to form an Array to Object Array with an passed key */
export const formatObjectArray = (dataArray, key) => {
    const objectArray = [];
    dataArray.forEach((element) => {
        const currentObject = {
            [key]: element.id
        };
        objectArray.push(currentObject);
    });
    return objectArray;
};
/** Funktion setzt das Dropdown Label für Type zusammen im entsprechenden Pattern */
export const formatObjectArrayTypes = (dataArray, key) => {
    return dataArray.map(element => ({
        ...element,
        [key]: `${element.name ? element.name : ""} ${element.type_kw}kw  (${formatYearMonth(element.type_from)} - ${formatYearMonth(element.type_to)}) `
    }));
};

export const formatObjectArrayModelLabel = (dataArray, key, kindId) => {
    /** Special Formating for LKW */
    if (kindId === 2) {
        return dataArray.map(element => {
            const tonnageAndAxles = (element.tonnage && element.axles) ? ` ( ${element.tonnage / 1000}t - ${element.axles} ) ` : "";
            return ({...element, [key]: `${element.name} ${tonnageAndAxles}`});
        });
    }
    return dataArray.map(element => (
        {
            ...element, [key]: `${element.model_name} - ${element.variant_name}`
    }
        ));
};

/** returns current Dropdown Index */
export const getCurrentDropdownIndex = (dropdownFields, fieldName) => {
    return dropdownFields.findIndex(element => element.name === fieldName);
};
/** return label of model */
export const getModelLabel = (dropDownField) => {
    const filteredItem = dropDownField.items.filter((element => {

        if (dropDownField.currentItem === '') {
            return null;
        }
        // const selected_model_id = dropDownField.currentItem.split('_')[1];
        // const selected_variant_id = dropDownField.currentItem.split('_')[0];
        if (element.model_id === dropDownField.currentItem.model_id 
            && element.variant_id === dropDownField.currentItem.variant_id) {
            return element.label;
        }
        else {
            return null;
        }
    }));
    return filteredItem[0]?.label;

};

export const langChecked = (lang) => {
    try {
        require("../data/" + lang + ".json");
        return lang;
    } catch (err) {
        return DEFAULT_CONFIG;
    }
};

// Because in typeinfo unformatted type_to and type_from look like (201703 - 201808)
export const formatYearMonth = (text) => {
    if (typeof text === 'string' && text.length === 6) {
        return text.slice(0, 4) + '.' + text.slice(4);
    }
    return text;
}

export const formatYear = (text) => {
    if (typeof text === 'string' && text.length === 6) {
        return text.slice(0, 4);
    }
    return text;
}

const checkEtrackerIsAvailable = () => typeof window._etracker === 'object' && typeof window._etracker.sendEvent === 'function';

export const sendEtrackerClickEventAndValue = (objectName, objectValue) => {
    sendEtrackerClickEvent(objectName, 'click');
    sendEtrackerClickEvent(objectName, objectValue);
}

export const sendEtrackerClickEvent = (value1, value2) => {
    if (checkEtrackerIsAvailable()) {
        const clickEvent = new window.et_ClickEvent(value1, value2);
        window._etracker.sendEvent(clickEvent);
    } else {
        setTimeout(() => {
            if (checkEtrackerIsAvailable()) {
                const clickEvent = new window.et_ClickEvent(value1, value2);
                window._etracker.sendEvent(clickEvent);
            } else {
                console.log('sendEtrackerClickEvent', value1, value2);
            }
        }, 3000);
    }
}

export const sendEtrackerDropdownEvent = (dropdownName, selectedOption) => {
    if (typeof dropdownName === 'string') {
        dropdownName = dropdownName.replace('brand.', '');
        if (typeof selectedOption.name === 'string') {
            sendEtrackerClickEventAndValue('Dropdown ' + dropdownName, selectedOption.name);
        } else if (typeof selectedOption.label === 'string') {
            sendEtrackerClickEventAndValue('Dropdown ' + dropdownName, selectedOption.label);
        }
    }
}

export const sendEtrackerKindEvent = (selectedOption) => {
    if (typeof selectedOption === 'number' || typeof selectedOption === 'string') {
        const selected = selectedOption.toString();
        let vehicleKindName;
        switch (selected) {
            case '1':
                vehicleKindName = 'car';
            break;
            case '2':
                vehicleKindName = 'truck';
            break;
            case '3':
                vehicleKindName = 'motorcycle';
            break;
            default:
                vehicleKindName = selectedOption;
            break;
        }
        sendEtrackerClickEventAndValue('Vehicle kind', vehicleKindName);
    }
}

export const sendEtrackerInputEvent = (inputName, inputValue) => {
    if (typeof inputName === 'string' && (typeof inputValue === 'string' || typeof inputValue === 'number')) {
        let inputNameDescription;
        inputName = inputName.toLowerCase();
        switch (inputName) {
            case 'part':
                inputNameDescription = 'Search by ECE or order no.';
            break;
            case 'kba':
                inputNameDescription = 'Search by KBA no.';
            break;
            default:
                inputNameDescription = inputName;
            break;
        }
        sendEtrackerClickEventAndValue(inputNameDescription, inputValue);
    }
}

export const sendEtrackerChangeEvent = (step) => {
    sendEtrackerClickEvent('Adapt search', 'click');
    if (typeof step === 'string' || typeof step === 'number') {
        step = step.toString();
        let changeStepName;
        switch (step) {
            case '0':
                changeStepName = 'change vehicle';
            break;
            case '1':
                changeStepName = 'change lamp position';
            break;
            case '2':
                changeStepName = 'change technology';
            break;
            case '3':
                changeStepName = 'change product';
            break;
            default:
                changeStepName = step;
            break;
        }
        sendEtrackerClickEvent('Adapt search', changeStepName);
    }
}

// Remove literal unescaped '&nbsp;' from strings because it can't be done in PIM or API.
export const trimWhitespace = (text) => {
    if (typeof text === 'string') {
        let result = text.replace('&nbsp;', ' ');
        return result.trim();
    }
    return text;
}