// These must be the first lines in src/index.js
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React, {Suspense, StrictMode} from "react";
import ReactDOM from "react-dom";
import {CookiesProvider} from "react-cookie";
import {Provider} from "react-redux";

import "./index.css";
import App from "./App";
import ErrorReport from "./ErrorReport";
import CookieStorageIntlProvider from "./components/CookieStorageIntlProvider";
import {initStore} from "./redux";

const store = initStore();

ReactDOM.render(
  <StrictMode>
    <Suspense fallback={<h2>Loading...</h2>}>
        <ErrorReport>
            <CookiesProvider>
                <CookieStorageIntlProvider initialLocale='en'>
                    <Provider store={store}>
                        <App />
                    </Provider>
                </CookieStorageIntlProvider>
            </CookiesProvider>
        </ErrorReport>
    </Suspense>
  </StrictMode>
    , document.getElementById("gvlrgroot"));

