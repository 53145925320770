import React from "react";
import PropTypes from "prop-types";

import InputItem from "./InputItem";
import {connect} from "react-redux";
import {getInputFieldErrors, getInputFieldValues, getQueryParams} from "../../redux/selectors";

const InputGroup = (props) => {
    return (
        <>
            {props.inputFields.map(element => {
                if (element.visibleInKind.indexOf(props.queryParams.kind) === -1) {
                    return null;
                }
                if (element.inputId === 1) {
                    return null;
                }
                return <InputItem
                    key={"input_" + element.queryKey}
                    inputName={element.queryKey}
                    titleId={element.titleId}
                    subtitleId={element.subtitleId}
                    withInfoImage={element.withInfoImage}
                    imageUrl={element.imageUrl}
                    handleChange={props.handleChange}
                    inputValue={props.inputValues[element.queryKey] || ""}
                    errorMessage={props.inputErrors[element.queryKey] ? element.errorMessage : ""}
                />;
            })}

        </>
    );
};

InputGroup.propTypes = {
    inputFields: PropTypes.array,
    inputValues: PropTypes.object,
    inputErrors: PropTypes.object,
    queryParams: PropTypes.object,
    handleChange: PropTypes.func,
    getValue: PropTypes.func
};

const mapStateToProps = state => ({
    inputValues: getInputFieldValues(state),
    inputErrors: getInputFieldErrors(state),
    queryParams: getQueryParams(state)
});

export default connect(mapStateToProps, null)(InputGroup);
