/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {createRef, useCallback, useEffect, useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {apiService} from "../../service/Apiservice";
import {sendEtrackerClickEvent} from "../../util/utilFkt";
import DropdownGroup from "../../components/Dropdown/DropdownGroup";
import {BUY_NOW_ASSET_DOMAIN} from "../../constant";
import {BYNDER_PARAMS} from "../../constant";

const BuyButton = props => {
  const [toggleDisplayModal, setToggleDisplayModal] = useState(false);
  const [productBuyOptions, setProductBuyOptions] = useState(null);
  const [filter, setFilter] = useState({name: "", label: ""});
  const ref = createRef();
  const {selectedProductDetails, configLang, t} = props;

  const fetchProductBuyOptions = useCallback(async () => {
    try {
      const {ean} = selectedProductDetails;
      const response = await apiService.fetchBuyNow(ean, configLang, false);
      return response[0];
    } catch (e) {
      // fail silently for now
      return null;
    }
  }, [selectedProductDetails, configLang]);

  useEffect(() => {
    fetchProductBuyOptions().then(response => {
      if (response !== null) {
        /**
         * We expect response.shops to be an array of object
         * this is the only key we need from the response.
         */
        if (Array.isArray(response.shops)) {
          setProductBuyOptions(response.shops);
          /**
           * We want a default filter, since the list is dynamically
           * generated, lets just grab the first array.
           */
          const dropdownFieldItems = getDropDownFields(response.shops);
          setFilter(dropdownFieldItems[0])

        }
      }
    }).catch(error => {
      console.error(error);
    });
  }, [fetchProductBuyOptions]);

  const handleOnClick = (event) => {
    event.preventDefault();
    sendEtrackerClickEvent("Buy Button", "click");
    toggleModal();
  }

  const toggleModal = () => setToggleDisplayModal(prevState => !prevState);

  const getDropDownFields = (productBuyOptions) => {
    if (Array.isArray(productBuyOptions)) {
      return Object.values(
        productBuyOptions.reduce((a, c) => {
          const {packageType} = c;
          a[packageType] = {
            name: packageType,
            label: packageType,
          };
          return a;
        }, {}))
    }
  }

  if (!productBuyOptions) {
    return <></>;
  }

  const dropDownFieldItems = getDropDownFields(productBuyOptions);
  const displayLabelAndDropDownDown = dropDownFieldItems.length > 1;

  return (
    <>
      <a
        style={{cursor: 'pointer'}}
        onClick={handleOnClick}
        className="with-icon-left link-btn orange-action-hover-btn btn-hover-fix but-now-btn"
        title={t("product.buynowlabel")}>
        <svg className="icon-cart-white"/>
        {t("product.buynowlabel")}
      </a>
      <Modal
        show={toggleDisplayModal}
        onHide={toggleModal}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>{selectedProductDetails.linecard_name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {displayLabelAndDropDownDown &&
          <DropdownGroup
            dropdownFields={[{
              name: 'buyNowFilter',
              titleId: t('buyNow.ModalBodyTitle'),
              currentItem: filter,
              items: dropDownFieldItems
            }]}
            dropdownRefs={[ref]}
            onChangeDropdown={(index, selected) => {
              setFilter(selected);
            }}
          />
          }
          <div className="BuyButton__layout-grid">
            {productBuyOptions
              .filter(productBuyOption => productBuyOption.active && filter.name === productBuyOption.packageType)
              .map((shop) => {
                const {assetId, productId, url, domain,bynder_link} = shop;
                return (
                  <div key={`${assetId}-${productId}`}>
                    <div className="BuyButton__container">
                      <div className="BuyButton__layout-flex">
                        <img
                          className="BuyButton__image"
                          src={`${bynder_link}?${BYNDER_PARAMS}`}
                          alt={`${domain}-${productId}`}
                        />
                      </div>
                    </div>
                    <div className="BuyButton__layout-flex">
                      <a
                        className="link-btn orange-action-hover-btn btn-hover-fix BuyButton__anchor"
                        href={url} target="_blank" rel="noopener noreferrer">
                        {t('buyNow.ModalBuyNowButton')}
                      </a>
                    </div>
                  </div>
                )
              })
            }
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

BuyButton.propTypes = {
  selectedProductDetails: PropTypes.shape({
    color_code: PropTypes.string,
    ean: PropTypes.string,
    ece: PropTypes.string,
    keyvisual_id: PropTypes.string,
    linecard_id: PropTypes.string,
    linecard_name: PropTypes.string,
    pillar_id: PropTypes.string,
    prio: PropTypes.string,
    usp: PropTypes.string
  }).isRequired,
  configLang: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default withTranslation()(BuyButton);