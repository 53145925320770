import {ActionTypes} from "./actions";
import {Cookies} from "react-cookie";
import {COOKIE_NAMES, DEFAULT_CONFIG, CONFIG_LANG_KEY} from "../constant";
import {langChecked} from "../util/utilFkt";

const cookies = new Cookies();
/** Set Cookie Language or use Default Config */
const lang = cookies.get(COOKIE_NAMES.LANG) ?? DEFAULT_CONFIG;

export const initialState = {
    queryParams: {
        [`${CONFIG_LANG_KEY}`]: langChecked(lang),
        lang: lang,
        kind: null,
        ean: null,
        bulb: null,
        technology: null,
        brand: null,
        model: null,
        year: null,
        type: null,
        car: null,
        kba: null,
        part: null,
        variant: null
    },
    progressBarIndex: 0,
    completedIndex: 0,
    data: {
        kind: {
            kindId: null,
            brand: {
                inputFieldValues: {},
                inputFieldErrors: {},
                dropdownFields: [],
                bulb: {
                    bulbs: [],
                    technology: {
                        technologies: [],
                        product: {
                            products: [],
                            description: null
                        }
                    }
                }
            }
        }
    },
    loadingStateClass: ""
};

export const reducer = (state = initialState, action) => {
    switch (action.type) {
        case ActionTypes.SET_INPUT_FIELD_VALUE:
            return inputFieldValueReducer(state, action);
        case ActionTypes.SET_INPUT_FIELD_ERROR:
            return inputFieldErrorReducer(state, action);
        case ActionTypes.SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: {
                    ...state.queryParams,
                    ...action.payload
                }
            };
        case ActionTypes.SET_DROPDOWN_FIELD_VALUES:
            return dropDownFieldValueReducer(state, action);
        case ActionTypes.SET_BULB_VALUES:
            return bulbFieldValueReducer(state, action);
        case ActionTypes.SET_TECHNOLOGY_VALUES:
            return technologyFieldValueReducer(state, action);
        case ActionTypes.SET_KIND:
            return {
                ...state,
                data: {
                    kind: {
                        ...action.payload,
                        brand: initialState.data.kind.brand
                    }
                }
            };
        case ActionTypes.SET_PROGRESSBAR_INDEX:
            return {
                ...state,
                progressBarIndex: parseInt(action.payload.index)
            };
        case ActionTypes.SET_PRODUCTS:
            return productFieldValueReducer(state, action);
        case ActionTypes.SET_COMPLETED_INDEX:
            return {
                ...state,
                completedIndex: parseInt(action.payload.index)
            };
        case ActionTypes.SET_PRODUCT_DESCRIPTION:
            return descriptionFieldValue(state, action);
        case ActionTypes.RESET_ALL:
            return initialState;
        case ActionTypes.SET_LOADING_STATE_CLASS:
            return {
                ...state,
                loadingStateClass: action.payload
            }
        default:
            return state;
    }
};


const inputFieldValueReducer = (state, action) => {
    return {
        ...state,
        queryParams: {
            ...state.queryParams
        },
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    inputFieldValues: {
                        ...state.inputFieldValues,
                        [action.payload.inputName]: action.payload.inputValue
                    },
                    bulb: initialState.data.kind.brand.bulb
                }
            }
        }
    };
};

const inputFieldErrorReducer = (state, action) => {
    return {
        ...state,
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    inputFieldErrors: {
                        ...state.inputFieldErrors,
                        [action.payload.inputName]: action.payload.hasError
                    }
                }
            }
        }
    };
};


const bulbFieldValueReducer = (state, action) => {
    return {
        ...state,
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    bulb: {
                        bulbs: action.payload
                    }
                }
            }
        }
    };
};

const technologyFieldValueReducer = (state, action) => {
    return {
        ...state,
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    bulb: {
                        ...state.data.kind.brand.bulb,
                        technology: {
                            technologies: action.payload
                        }
                    }
                }
            }
        }
    };
};

const productFieldValueReducer = (state, action) => {
    return {
        ...state,
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    bulb: {
                        ...state.data.kind.brand.bulb,
                        technology: {
                            ...state.data.kind.brand.bulb.technology,
                            product: {
                                products: action.payload
                            }
                        }
                    }
                }
            }
        }
    };
};

const descriptionFieldValue = (state, action) => ({
    ...state,
    data: {
        kind: {
            ...state.data.kind,
            brand: {
                ...state.data.kind.brand,
                bulb: {
                    ...state.data.kind.brand.bulb,
                    technology: {
                        ...state.data.kind.brand.bulb.technology,
                        product: {
                            ...state.data.kind.brand.bulb.technology.product,
                            description: action.payload
                        }
                    }
                }
            }
        }
    }
});

const dropDownFieldValueReducer = (state, action) => {
    return {
        ...state,
        data: {
            kind: {
                ...state.data.kind,
                brand: {
                    ...state.data.kind.brand,
                    dropdownFields: {
                        ...action.payload
                    },
                    bulb: initialState.data.kind.brand.bulb
                }
            }
        }
    };
};
