import React, {Component} from "react";
import { withTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Select from 'react-select';
import { sendEtrackerDropdownEvent } from "../../util/utilFkt";

class DropdownItem extends Component {
    static propTypes = {
        titleId: PropTypes.string,
        selectId: PropTypes.string,
        name: PropTypes.string,
        index: PropTypes.number,
        isCompleted: PropTypes.bool,
        onChangeDropdown: PropTypes.func,
        items: PropTypes.array,
        disabled: PropTypes.bool,
        currentItem: PropTypes.any,
        t: PropTypes.func.isRequired,
        loading: PropTypes.bool,
        dropdownRef: PropTypes.any
    };
    
    handleChange = (selectedOption) => {
        sendEtrackerDropdownEvent(this.props.titleId, selectedOption);
        this.props.onChangeDropdown(this.props.index, selectedOption);
    }
    
    render() {
        return (
            <div>
                <h6 className="brand-select-headline">{this.props.t(this.props.titleId)}</h6>
                <div id={this.props.name} className="styled-react-select">
                    <Select
                        ref={this.props.dropdownRef}
                        name={this.props.name}
                        options={this.props.items}
                        onChange={this.handleChange}
                        value={this.props.currentItem}
                        getOptionLabel={(option) => option.label ?? option.name}
                        getOptionValue={(option) => option}
                        isDisabled={this.props.items.length <= 1}
                        placeholder={this.props.t(this.props.selectId)}
                        classNamePrefix="react-select"
                        styles={customStyles}
                        isLoading={this.props.loading}
                    />
                </div>
            </div>
        );
    }
}

const customStyles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'black' : 'black',
    //   backgroundColor: state.isSelected ? 'gray' : 'white',
    }),
    indicatorSeparator: () => ({
        display: 'none'
    }),
    control: (state) => ({
        display: 'flex',
        border: '1px solid #C8C8C8',
        fontSize: '16px',
        padding: '5px',
    }),
    placeholder: (provided, state) => ({
        ...provided,
        color: state.isDisabled ? '#ADADAD' : 'black',
        display: state.isSelected ? 'none' : '',
    }),
    indicatorsContainer: (state, { isDisabled}) => ({
        color: isDisabled ? '#ADADAD' : '#f60',
        display: 'flex',
    }),
    singleValue: (provided, state) => {
      const transition = 'opacity 300ms';  
      return { ...provided, transition };
    }
  }

export default withTranslation()(DropdownItem);
