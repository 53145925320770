/** constants for dropdown names */
export const BRAND_DROPDOWNS = {
    BRAND: "brand",
    MODEL: "model",
    YEAR: "year",
    TYPE: "type",
    LAST: "last"
};

/**
 * Constants for InputFields
 * @type {{PART: string, KBA: string}}
 */
export const QueryKeys = {
    KBA: "kba",
    PART: "part"
};

/** Keys for new key value pairs for items*/
export const DROPDOWN_OPJECT_KEY = "name";
export const DROPDOWN_OBJECT_KEY_LABEL = "label";

/** Cookie Constants */

export const COOKIE_NAMES = {
    VEHICLE:"gvlrgvehicle",
    KBA: "gvlrgvehiclekba",
    LANG: "gvlrglang"
}

export const DEFAULT_CONFIG = "de_DE";

/** Constants for Sorting Brand Overview */
export const BRAND_OVERVIEW_ORDER_ATTRIBUTE ="brandOverviewPos";
export const BRAND_OVERVIEW_ORDER_SORT ="ASC";


/** Share Button */
export const SHARE_BUTTON_RESOURCES = {
    DAM: "dam",
    OSRAM_MEDIA: "osrammedia"
}
export const OSRAM_MEDIA_BASEURL = "https://www.osram.com/media/resource";
export const SHARE_MAIL_LOGO = "https://media.osram.info/im/img/osram-dam-10349359/s,x,100,y,0/emailalsjpg.jpg";
export const MEDIA_ENDPOINT = "https://dammedia.osram.info";

export const PROGRESS_BAR = {
    STEP1: 0,
    STEP2: 1,
    STEP3: 2,
    STEP4: 3
}

/** Fallback URL for BuyNow Button*/
export const BUYNOW_FALLBACK_URL = "http://www.google.com";

export const CONFIG_LANG_KEY = "configLang";

export const BUY_NOW_ASSET_DOMAIN = "https://dammedia.osram.info/media/resource/240";
export const BYNDER_PARAMS = "io=transform:scale,width:121,height:42";

export const FALLBACK_LANG_API = 'en';