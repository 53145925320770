/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import shortId from "shortid";
import {useSelector} from "react-redux";

import {getQueryParams} from "../redux/selectors";
import { sendEtrackerChangeEvent } from "../util/utilFkt";

const ProgressBar = (props) => {
    /** Import Field Data */
    const queryParams = useSelector(state => getQueryParams(state));
    const fieldDataImport = require(`../data/${queryParams.configLang}.json`);
    const {t, activeIndex, selectDots, completedIndex} = props;

    return (
        <ul className="progress-tracker progress-tracker--text progress-tracker--center progress-tracker--spaced anim-path">
            {fieldDataImport.progressBar.map((element, index) => {
                return (
                    <li key={shortId.generate()}
                        className="progress-step">
                        <a btattached="true">
                            <span
                                className="progress-marker"
                                id={index === activeIndex ? "is-active" : index <= completedIndex ? "is-complete" : ""}
                                onClick={() => {
                                    sendEtrackerChangeEvent(index);
                                    (index <= activeIndex || index <= completedIndex) && selectDots(index);
                                }}
                            >
                                {element.step}
                            </span>
                            <span className="progress-text">
                                <div
                                    className="progress-title"
                                    id={index === activeIndex ? "is-active-text" : index <= completedIndex ? "is-complete-text" : ""}
                                    onClick={() => {
                                        sendEtrackerChangeEvent(index);
                                        (index <= activeIndex || index <= completedIndex) && selectDots(index);
                                    }}
                                >
                                    {t(element.title)}
                                </div>
                            </span>
                        </a>
                    </li>
                );
            })}
        </ul>
    );
};

ProgressBar.propTypes = {
    activeIndex: PropTypes.number.isRequired,
    completedIndex: PropTypes.number.isRequired,
    selectDots: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired
};
export default withTranslation()(ProgressBar);
