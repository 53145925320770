import React, {createRef, useEffect} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {withTranslation} from "react-i18next";

import {getInputFieldErrors, getQueryParams} from "../../redux/selectors";
import {DE_LOCALE, INPUT_TYPE_HSN, INPUT_TYPE_KBA} from "../../constant/search";
import { useValidateHSN } from "./useValidateHSN";

/**
 *
 * @param t
 *  Translation function, bought in by withTranslationHOC.
 * @param handleChange
 *  Main form handler function, see handleInputFieldChange in brand.js for more details.
 * @param inputErrors
 *  Returns object of errors to respective fields.
 * @param inputFields
 *  Returns an object of fields data that is set inside inputFields key in data folder.
 * @param configLang
 *  locale supplied in XX_XX format.
 * @param queryParams
 *  returns a large of object of varies key/values, this component only use "kind" key.
 *  See reducers.js for complete list.
 * @param setLoadingStateClass
 *  returns a functions which controls the brand.js loader class.
 * @returns {JSX.Element|null}
 * @constructor
 */
export const Search = ({t, handleChange, inputErrors, inputFields, configLang, queryParams, setLoadingStateClass}) => {
  const {
    toggleKBAInputDisabled,
    toggleHSNInputDisabled,
    validateHSN,
    validationHSNErrors,
    validatedHSN,
  } = useValidateHSN({setLoadingStateClass});

  const HSNRef = createRef();
  const KBARef = createRef();

  useEffect(() => {
    // Bring KBA input into focus whenever we have validated HSN field.
    if (!toggleKBAInputDisabled && !KBARef.current.value && HSNRef.current.value.length === 4) {
      KBARef.current.focus();
    }
  }, [toggleKBAInputDisabled, KBARef, HSNRef])

  // This Form should only be available on DE for now
  if (!inputFields[0]?.visibleInKind.includes(queryParams.kind) || configLang !== DE_LOCALE) {
    return null;
  }

  const SEARCH_EL = inputFields[0];

  const doHandleChange = () => handleChange({name: INPUT_TYPE_KBA, value: `${validatedHSN}${KBARef.current.value}`})

  const handleOnKeyDown = (event) => {
    if (event.key === "Enter") {
      if (!toggleKBAInputDisabled && HSNRef.current.value.length === 4 && KBARef.current.value.length === 3) {
        doHandleChange();
      }
    }
  };

  const handleOnKeyUpHSNInput = () => validateHSN(HSNRef.current.value, configLang);

  return (
    <div>
      <h5>{t(SEARCH_EL.titleId)}</h5>
      {SEARCH_EL.withInfoImage ?
        <h6 className="info">
          {t(SEARCH_EL.subtitleId)}
          <OverlayTrigger
            overlay={
              <Popover id="popover">
                <img
                  className="popup"
                  src={process.env.PUBLIC_URL + SEARCH_EL.imageUrl}
                  alt="info"
                />
              </Popover>
            }
            trigger={["hover", "focus"]}
            placement="top"
          >
            <span className="info ms-icon_help"/>
          </OverlayTrigger>
        </h6> :
        <h6>{t(SEARCH_EL.subtitleId)}</h6>
      }

      <form onKeyDown={handleOnKeyDown}>
        <div className="brand-search-input input-group">
          <input
            disabled={toggleHSNInputDisabled}
            placeholder={t('search.placeholderHSN')}
            ref={HSNRef}
            type="text"
            pattern="[A-Za-z0-9]{3}"
            maxLength={4}
            name={INPUT_TYPE_HSN}
            defaultValue={validatedHSN}
            className="form-control input__search-left"
            onKeyUp={handleOnKeyUpHSNInput}
          />

          <div className="Search__position-relative">
            <span className="Search__slash">/</span>
          </div>

          <input
            placeholder={t('search.placeholderKBA')}
            ref={KBARef}
            disabled={toggleKBAInputDisabled}
            type="text"
            pattern="[A-Za-z0-9]{3}"
            maxLength={3}
            name={INPUT_TYPE_KBA}
            className="form-control input__search-right input__search-uppercase"
          />
          <span onClick={doHandleChange} className="input-group-addon ms-icon_loupe"/>
        </div>
      </form>
      {(validationHSNErrors || inputErrors.kba) && <p className="notfound">{t(SEARCH_EL.errorMessage)}</p>}
    </div>
  );
};

Search.propTypes = {
  inputFields: PropTypes.arrayOf(
    PropTypes.shape({
      inputID: PropTypes.number,
      errorMessage: PropTypes.string,
      imageUrl: PropTypes.string,
      queryKey: PropTypes.string,
      subtitleId: PropTypes.string,
      titleId: PropTypes.string,
      visibleInKind: PropTypes.arrayOf(PropTypes.number),
      withInfoImage: PropTypes.bool
    })
  ).isRequired,
  inputErrors: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  configLang: PropTypes.string.isRequired,
  queryParams: PropTypes.object.isRequired,
  setLoadingStateClass: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  inputErrors: getInputFieldErrors(state),
  queryParams: getQueryParams(state)
});

export default connect(mapStateToProps, null)(withTranslation()(Search));
