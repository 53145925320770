import React, {useState} from "react";
import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import { TrackingContactIframeButton } from "../../constant/tracking";
import { sendEtrackerClickEvent } from "../../util/utilFkt";

const ContactIframeButton = props => {
    const [toggleDisplayModal, setToggleDisplayModal] = useState(false);
    const {t, externalURL} = props;

    const toggleModal = () => setToggleDisplayModal(prevState => !prevState);

    const handleOnClick = () => {
      sendEtrackerClickEvent(TrackingContactIframeButton.type, TrackingContactIframeButton.event);
      toggleModal()
    };

    return (
        <>
            <button
              onClick={handleOnClick}
              className="icon-link-c-custom"
              data-copyto=""
              title={t("product.contactus")}
              style={{cursor: 'pointer'}}>
                <svg className="icon-email" />
                {t("product.contactus")}
            </button>
          <Modal
            show={toggleDisplayModal}
            onHide={toggleModal}
            backdrop="static"
          >
            <Modal.Header closeButton />
            <Modal.Body className="modal-body-preselctcar modal-body-contact">
              <iframe title={t("product.contactus")} src={externalURL} scrolling="yes" />
            </Modal.Body>
          </Modal>

        </>
    );
};

ContactIframeButton.propTypes = {
    externalURL: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ContactIframeButton);
