import React from "react";
import PropTypes from "prop-types";
import shortId from "shortid";

import DropdownItem from "./DropdownItem";

const DropdownGroup = (props) => {
    return (
        <>
            {props.dropdownFields.map((element, index) => {
                return <DropdownItem
                    key={shortId.generate()}
                    titleId={element.titleId}
                    selectId={element.selectId}
                    name={element.name}
                    isCompleted={element.isCompleted}
                    index={index}
                    onChangeDropdown={props.onChangeDropdown}
                    items={element.items}
                    disabled={element.disabled}
                    currentItem={element.currentItem}
                    loading={element.loading}
                    dropdownRef={props.dropdownRefs[index]}
                />;
            })}

        </>
    );
};

DropdownGroup.propTypes = {
    dropdownFields: PropTypes.array,
    onChangeDropdown: PropTypes.func,
    dropdownRefs: PropTypes.array
};

export default DropdownGroup;
