import React, {Component} from "react";
import PropTypes, {instanceOf} from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {Cookies, withCookies} from "react-cookie";

import {setProductDescription, setQueryParams, setLoadingStateClass} from "../redux/actions";
import {getProducts, getProgressBarIndex, getQueryParams} from "../redux/selectors";
import CollapsePanel from "./CollapsePanel/CollapsePanel";
import {COOKIE_NAMES, PROGRESS_BAR} from "../constant";
import {apiService} from "../service/Apiservice";
import {MEDIA_ENDPOINT} from "../constant";

import { sendEtrackerClickEvent, sendEtrackerClickEventAndValue, trimWhitespace } from "../util/utilFkt";

class Products extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
        activeIndex: PropTypes.number,
        queryParams: PropTypes.object.isRequired,
        productsList: PropTypes.array.isRequired,
        setQueryParams: PropTypes.func.isRequired,
        setProductDescription: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        setLoadingStateClass: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            cookie: this.props.cookies.get(COOKIE_NAMES.VEHICLE),
            cookieCheckBox: !!this.props.cookies.get(COOKIE_NAMES.VEHICLE),
            selectedProduct: this.props.queryParams.ean ?? ""
        };
    }

    componentDidMount() {
        // Resolve Query Params from URL
        this.props.queryParams.ean && this.handleProductDetails(this.props.queryParams.ean);

    }

    // When product has been clicked in a pillar.
    selectProduct = (ean) => {
        // Set product description to null to hide product description container
        // until new product is done loading.
        this.props.setProductDescription(null);
        // Then fetch new product.
        this.handleProductDetails(ean);
    };

    handleProductDetails = (productZMP) => {
        this.props.setQueryParams({ean: productZMP});
        this.props.setLoadingStateClass("loading-description");
        apiService.fetchDescription(this.props.queryParams.lang, productZMP).then(result => {
            this.props.setLoadingStateClass("");
            // Api sometimes returns bad data with code 400 even for valid eans,
            // apiService returns them as null so check for null here.
            if (result) {
                // Check for literal &nbsp; here because it can't be handled in API or PIM.
                if (typeof result.oece === 'string') {
                    result.oece = trimWhitespace(result.oece);
                }
                this.props.setProductDescription(result);
                const productContainer = document.getElementById('vlrg-product-info');
                if (productContainer !== null) {
                    productContainer.scrollIntoView();
                }
            }
        });

    };

    getProductImageUrlOrPlaceholder = (product) => {
        const productImageBaseUrl = MEDIA_ENDPOINT + "/im/img/";
        const productImageUrlAfter = `/c/s,x,320,y,0/image.jpg`;
        if (typeof product.keyvisual_id !== 'string' || product.keyvisual_id.trim().length === 0) {
            return "product-placeholder.jpg"
        } else {
            return productImageBaseUrl + product.keyvisual_id + productImageUrlAfter;
        }
    }

    renderPillar(title, subtitle, pillars) {
        if (pillars == null || pillars.length === 0) {
            return null;
        }
        else {
            return (
                <div>
                    <h5>
                        <span>
                            {title}
                        </span>
                        {subtitle}
                    </h5>
                    <ul>
                        {pillars ? pillars.map((product) =>
                            <li key={product.ean}
                                onClick={() => {
                                    sendEtrackerClickEventAndValue("product", product.ean);
                                    this.setState({selectedProduct: product.ean});
                                    this.selectProduct(product.ean);
                                }}
                                className="product-card-outer">
                                <div
                                    className={"bulpList product-card" + (product.ean === this.state.selectedProduct ? ' selected' : '')}
                                    id="display"                                    
                                >
                                    <img src={this.getProductImageUrlOrPlaceholder(product)} style={{"maxWidth": "100%"}} alt={product.linecard_name}></img>
                                </div>
                                <div className="product-card-text">
                                    <span className="text-orange product-card-name">
                                        {product.linecard_name}
                                    </span>
                                    <span className="text-black product-card-description">
                                        {product.usp}
                                    </span>
                                </div>
                            </li>
                        ) : this.props.t("product.loading")}
                    </ul>
                </div>);
        }
    }

    changeCookie = (e) => {
        this.setState({cookieCheckBox: e.target.checked}, () => {
            const {cookieCheckBox} = this.state;
            const {cookies} = this.props;
            if (cookieCheckBox) {
                sendEtrackerClickEvent("Save Car Cookie", "click");
                this.setCookie();
            } else {
                sendEtrackerClickEvent("Remove Car Cookie", "click");
                cookies.remove(COOKIE_NAMES.VEHICLE, {path: "/"});
            }
            this.setState({cookie: this.props.cookies.get(COOKIE_NAMES.VEHICLE)});
        });
    };

    setCookie = () => {
        const {cookies, queryParams} = this.props;
        if (queryParams.car && queryParams.kind) {
            cookies.set(COOKIE_NAMES.VEHICLE, `car=${queryParams.car}&kind=${queryParams.kind}` , {path: "/"});
        } else {
            console.error("can't save car as a cookie because car or kind is not set", queryParams.car, queryParams.kind);
        }
    };

    render() {
        const {t, activeIndex, queryParams, productsList} = this.props;
        const {cookieCheckBox, cookie} = this.state;
        /** Import Field Data */
        const fieldDataImport = require(`../data/${this.props.queryParams.configLang}.json`);

        if (!productsList || productsList.length === 0) {
            if (queryParams.ean !== null) {
                return "";
            } else {
                return (<p>{t("product.noData")}</p>);
            }
        }
        const selected = productsList.find(product => product.ean === queryParams.ean);
        
        // since we're using colors returned from backend to flag pillar we can't display those without it
        let withColor = productsList.filter(product => product.color_code != null);
        let pillar1 = withColor.filter(product => product.pillar_id === "1");
        let pillar2 = withColor.filter(product => product.pillar_id === "2");
        let pillar3 = withColor.filter(product => product.pillar_id === "3");
        let pillar4 = withColor.filter(product => product.pillar_id === "4");
        let pillar5 = withColor.filter(product => product.pillar_id === "5");

        return (
            <CollapsePanel
                className="row products-pillars"
                isExpanded={activeIndex === PROGRESS_BAR.STEP4}
                progressbarIndex={PROGRESS_BAR.STEP4}
                selectedOption={selected?.linecard_name}
                title={t("product.compatibleProducts")}
                panelBody={
                    <div className="tech-parent">
                        <p className="select-product-family">{t("product.productsDescription")}</p>
                        {queryParams.part && 
                        <div className="your-matching-product">{t("product.yourMatchingProduct")} <span className="text-orange"> {this.props.queryParams.part}</span></div>
                        }
                        <p className="select-your-product">{t("product.pleaseChoseYourProduct")}</p>
                        <div className="tech">
                            {this.renderPillar(t("product.performanceTitle"), t("product.performanceDescription"), pillar1)}
                            {this.renderPillar(t("product.designTitle"), t("product.designDescription"), pillar2)}
                            {this.renderPillar(t("product.comfortTitle"), t("product.comfortDescription"), pillar3)}
                            {this.renderPillar(t("product.originalTitle"), t("product.originalDescription"), pillar4)}
                            {this.renderPillar(t("product.innovationTitle"), t("product.innovationdescription"), pillar5)}
                        </div>
                        {(queryParams.car !== null && queryParams.kind !== null) &&
                        <p className="check-cookie">
                            <label>
                                <input type="checkbox" checked={cookieCheckBox}
                                       onChange={(e) => this.changeCookie(e)} />
                                <span className="lbl">{cookie ? t("product.checkboxSelected") : t("product.checkboxNotSelected")}</span>
                            </label>
                        </p>}


                        {queryParams.car &&
                        <p>
                            {`${t("product.policyIntro")} `}
                            <a
                                href={fieldDataImport.cookiePolicy}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="cookie"
                            >
                                {t("product.policyCTA")}
                            </a>
                        </p>
                        }
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    productsList: getProducts(state),
    activeIndex: getProgressBarIndex(state)
});

const mapDispatchToProps = dispatch => ({
    setQueryParams: (items) => dispatch(setQueryParams(items)),
    setProductDescription: (description) => dispatch(setProductDescription(description)),
    setLoadingStateClass: (loadingStateClass) => dispatch(setLoadingStateClass(loadingStateClass))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withCookies(Products)));
