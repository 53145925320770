import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import {initReactI18next} from "react-i18next";
import {Cookies} from "react-cookie";

import {COOKIE_NAMES, DEFAULT_CONFIG} from "./constant";

const cookies = new Cookies();
const locale = cookies.get(COOKIE_NAMES.LANG) ?? DEFAULT_CONFIG;
const LOCALE_SEPARATOR = "_";
const splitLocale = locale.toLowerCase().split(LOCALE_SEPARATOR);
const lang = splitLocale[0];
const langCountry = splitLocale[1];
// const translationBase = process.env.REACT_APP_TRANSLATION_BASE || "https://wcms-web-dev.osram.info/apps/gvlrg/lang";
// Loading language.json with new values locally for testing.
const translationBase = process.env.REACT_APP_TRANSLATION_BASE || "";

i18n
.use(XHR)
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    backend: {
        loadPath: () => {
            if (lang === "en" && langCountry === "uk") {
                return `${translationBase}/${lang}-${langCountry}.json`;
            }
            if (lang === "en" && langCountry === "se") {
                return `${translationBase}/${langCountry}.json`;
            }
            if (lang === 'en' && langCountry === 'gb') {
                return `${translationBase}/${lang}-${langCountry}.json`;
            }
            return `${translationBase}/${lang}.json`;
        },
        crossDomain: false
    },

    // lng: lang, // This cause lang file to be loaded twice.
    fallbackLng: "en",
    ns: ["translations"],
    defaultNS: "translations",
    debug: false,

    keySeparator: true, // we use keys in form messages.welcome

    interpolation: {
        escapeValue: false // react already safes from xss
    }
});

export default i18n;
