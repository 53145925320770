import React, {Component} from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import {apiService} from "../service/Apiservice";
import {getBulbs, getProgressBarIndex, getQueryParams} from "../redux/selectors";
import {setCompletedIndex, setQueryParams, setTechnologyValues, setLoadingStateClass} from "../redux/actions";
import {bulbIcons, bulbPosition} from "../constant/bulb";
import {PROGRESS_BAR} from "../constant";
import CollapsePanel from "./CollapsePanel/CollapsePanel";
import {bulbShape} from "../shapes";

import { sendEtrackerClickEventAndValue } from "../util/utilFkt";

class Bulb extends Component {
    static propTypes = {
        t: PropTypes.func,
        selectDots: PropTypes.func,
        setQueryParams: PropTypes.func,
        setTechnologies: PropTypes.func,
        setCompletedIndex: PropTypes.func,
        activeIndex: PropTypes.number,
        queryParams: PropTypes.object.isRequired,
        bulbs: PropTypes.arrayOf(bulbShape).isRequired,
        setLoadingStateClass: PropTypes.func.isRequired
    };


    componentDidMount() {
        // Resolve Query Params from URL
        this.props.queryParams.bulb && this.handleSelectedBulb(this.props.queryParams.bulb, true);
    }


    handleSelectedBulb = (id, resolver) => {
        this.props.setLoadingStateClass("loading-technology");
        this.props.setQueryParams({bulb: id});
        // Call callback function and say that is selection finish
        apiService.fetchTechnology(this.props.queryParams.lang, this.props.queryParams.car, id)
        .then(response => {
            this.props.setLoadingStateClass("");
            if (response !== null) {
                this.props.setTechnologies(response);
            } else {
                console.error("error loading technologies", response);
            }
        });
        //Reset Technology Query Param
        !resolver && this.props.setQueryParams({technology: null});

        //select dots for progressbar
        this.props.selectDots(PROGRESS_BAR.STEP3);
        // update completed Index
        this.props.setCompletedIndex(PROGRESS_BAR.STEP3);

    };

    /** Render BulbList only when bulbs at this position are available*/
    renderBulbList = (title, bulbs) => (
        <>
            {bulbs?.length > 0 && <div>
                <h5>{title}</h5>
                {bulbs.map((bulb, index) =>

                    <li className="bulpList icon-left ikona" onClick={() => {
                        sendEtrackerClickEventAndValue("Lamp position", bulb.use_name);
                        this.handleSelectedBulb(bulb.use_id);
                    }} key={index}>
                        <span className={bulbIcons.get(bulb.use_id) || "icon-bulp vanity-mirror-light-bulb"} /><p>{bulb.use_name}</p>
                    </li>
                )}
            </div>}
        </>
    );


    render() {
        let forward, exterior, interior;
        let selectedBulb = [];
        const {t, bulbs, queryParams} = this.props;
        if (bulbs?.length > 0) {
            forward = bulbs.filter(bulb => bulb.pos_id === bulbPosition.FORWARD);
            exterior = bulbs.filter(bulb => bulb.pos_id === bulbPosition.EXTERIOR);
            interior = bulbs.filter(bulb => bulb.pos_id === bulbPosition.INTERIOR);
            selectedBulb = bulbs.find(bulb => bulb.use_id === queryParams.bulb);
        }
        const selected = selectedBulb?.use_name ?? "";
        return (
            <CollapsePanel
                className="row bulb-positions"
                isExpanded={this.props.activeIndex === PROGRESS_BAR.STEP2}
                progressbarIndex={PROGRESS_BAR.STEP2}
                selectedOption={selected}
                title={t("bulb.bulbPosition")}
                panelBody={
                    <div className="bulp-panel">
                        {bulbs ? <div className="row">
                            <div className="col-md-6">
                                <div className="bulp-panel-left">
                                    {this.renderBulbList(t("bulb.forward"), forward)}
                                    {this.renderBulbList(t("bulb.exterior"), exterior)}
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div>
                                    {this.renderBulbList(t("bulb.interior"), interior)}
                                </div>
                            </div>
                        </div> : t("bulb.nothingFound")}
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    bulbs: getBulbs(state),
    activeIndex: getProgressBarIndex(state)
});
const mapDispatchToProps = dispatch => ({
    setQueryParams: (item) => dispatch(setQueryParams(item)),
    setTechnologies: (technologies) => dispatch(setTechnologyValues(technologies)),
    setCompletedIndex: (index) => dispatch(setCompletedIndex(index)),
    setLoadingStateClass: (loadingStateClass) => dispatch(setLoadingStateClass(loadingStateClass))
});
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Bulb));
