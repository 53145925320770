import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";

import ActionButtons from "../ActionButtons/ActionButtons";
import {getProductDescription, getProducts, getProgressBarIndex, getQueryParams} from "../../redux/selectors";
import ProductBenefits from "./ProductBenefits";
import ImageGallery from "./ImageGallery";
import {descriptionShape, productShape} from "../../shapes";

class ProductDescription extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        description: descriptionShape,
        products: PropTypes.arrayOf(productShape),
        queryParams: PropTypes.object
    };

    renderHTML = (rawHTML) => React.createElement(
        "div",
        {
            dangerouslySetInnerHTML: {
                __html: this.convertUnicode(rawHTML)
            }
        }
    );

    convertUnicode = input => {
        return input.replace(/\\u(\w\w\w\w)/g, function (a, b) {
            const charCode = parseInt(b, 16);
            return String.fromCharCode(charCode);
        });
    };

    render() {
        const {t, products, queryParams, description} = this.props;
        // When no product description is loaded render an empty container
        // to allow scrolling to this container by #id, this way it is not
        // necessary to wait for the container to fully render new data in
        // Products.js > handleProductDetails()   to scroll to it.
        if (!description) {
            return (<div id="vlrg-product-info" className="col-md-6 display right-col" />);
        }

        const selectedProduct = products.find(product => product.ean === queryParams.ean);
        const contactIframeUrl = `https://osram.formstack.com/forms/contactus_${this.props.queryParams.lang.toLowerCase()}?oonum=${encodeURIComponent(description.oonum)}&product=${encodeURIComponent(`${t("app.osram")} ${selectedProduct?.linecard_name} ${description.oece}`)}`

        return (
            <div id="vlrg-product-info" className="col-md-6 display right-col">
                <div className="right-column-content">
                    <div className="text-bold-orange">{t("product.yourSelection")}</div>
                    <h3 className="text-black-big">{t("app.osram")} {selectedProduct?.linecard_name} {description.oece}</h3>
                    <div className="action-links gray-line-top">
                        <ActionButtons
                            selectedProductDetails={selectedProduct}
                            queryParams={queryParams}
                            contactIframeUrl={contactIframeUrl}
                        />
                    </div>
                    <ImageGallery
                        assets={description.assets}
                    />
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="prod-r-details-headline gray-line-bottom">{t("product.detailsTitle")}</h4>
                            <div className="prod-r-details">
                                <div className="prod-r-details-header">{t("app.productLine")}</div>
                                <div className="prod-r-details-text">{selectedProduct?.linecard_name} {description.oece}</div>
                                <div className="prod-r-details-header">{t("app.orderNumber")}</div>
                                <div className="prod-r-details-text">{description.oonum}</div>
                                <div className="prod-r-details-header">{t("app.eceCategory")}</div>
                                <div className="prod-r-details-text">{description.oece}</div>
                                <div className="prod-r-details-header">{t("app.technicalData")}</div>
                                <div className="prod-r-details-text">{description.linfo}</div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="prod-r-feat">
                                <div>
                                    <h4 className="prod-r-details-headline gray-line-bottom">{t("product.productfeatureslabel")}</h4>
                                    <span className="" />
                                    {description.offroadinfotext ?
                                    this.renderHTML(description.offroadinfotext) :
                                    description.ledinfotext ?
                                    this.renderHTML(description.ledinfotext) :
                                    (description.benefits && description.benefits.length > 0) &&
                                    <ProductBenefits benefits={description.benefits} />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    progressBarIndex: getProgressBarIndex(state),
    products: getProducts(state),
    description: getProductDescription(state)
});

export default connect(mapStateToProps)(withTranslation()(ProductDescription));
