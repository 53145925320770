import React, {Component, Suspense} from "react";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import queryString from "query-string";
import {Cookies, withCookies} from "react-cookie";
import PropTypes, {instanceOf} from "prop-types";

import "./App.css";
import ProgressBar from "./components/ProgressBar";
import Brand from "./components/Brand";
import Bulb from "./components/Bulb";
import Note from "./components/Note";
import Products from "./components/Products";
import Vehicle from "./components/Vehicle";
import ProductDescription from "./components/ProductDescription/ProductDescription";

import {
    COOKIE_NAMES, DEFAULT_CONFIG, PROGRESS_BAR
}
    from "./constant";
import {
    getBulbs,
    getCompletedIndex,
    getKind,
    getProducts,
    getProgressBarIndex,
    getQueryParams,
    getTechnologies,
    getLoadingStateClass
} from "./redux/selectors";
import {
    resetAll,
    setCompletedIndex,
    setKind,
    setProgressBarIndex,
    setQueryParams,
    setLoadingStateClass
}
    from "./redux/actions";

import { sendEtrackerChangeEvent } from "./util/utilFkt";

class App extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
        queryParams: PropTypes.object.isRequired,
        bulbs: PropTypes.array.isRequired,
        kind: PropTypes.number,
        progressBarIndex: PropTypes.number.isRequired,
        completedIndex: PropTypes.number.isRequired,
        technologies: PropTypes.array,
        products: PropTypes.array,
        resetAll: PropTypes.func.isRequired,
        setQueryParams: PropTypes.func.isRequired,
        setKind: PropTypes.func.isRequired,
        setProgressBarIndex: PropTypes.func.isRequired,
        t: PropTypes.func.isRequired,
        loadingStateClass: PropTypes.string.isRequired
    };

    constructor(props) {
        super(props);
        const {cookies} = props;
        /** Set Cookie Language or use Default Config */
        const lang = cookies.get(COOKIE_NAMES.LANG) ?? DEFAULT_CONFIG;

        /** Dropdown Beispiel Strings
         * Dropdown: http://localhost:3000/?brand=ALFA%20ROMEO&model=2&year=2009&type=3&kind=1&bulb=2668&car=13203&technology=mit%20Halogenscheinwerfer&zmp=4052899991392
         * KBA: http://localhost:3000/?kind=1&zmp=4052899991309&lang=de_DE&bulb=2668&technology=mit%20Halogenscheinwerfer&kba=7100546&car=13522
         * Part: http://localhost:3000/?kind=1&zmp=4052899991309&lang=de_DE&part=h1
         */

        /** When QueryParams Available use them if not use cookies */
        const cookieInfo = cookies.get(COOKIE_NAMES.VEHICLE);
        const values = window.location.search ? queryString.parse(window.location.search) : queryString.parse(cookieInfo);

        const queryParams = {
            kind: parseInt(values.kind, 10) ?? null,
            ean: values.ean ?? null,
            lang: values.lang ?? lang,
            bulb: values.bulb ?? null,
            technology: values.technology ?? null,
            brand: values.brand ?? null,
            model: values.model ?? null,
            year: values.year ?? null,
            type: values.type ?? null,
            car: values.car ?? null,
            kba: values.kba ?? null,
            part: values.part ?? null,
            variant: values.variant ?? null
        };
        //Set all queryParams
        this.props.setQueryParams(queryParams);
        this.props.setKind(queryParams.kind);
        // Remove url parameters after they have been read into queryParams because
        // we are not updating them in real time anyway.
        // TODO: test separately.
        window.history.pushState({}, document.title, window.location.href.split("?")[0]);
    }

    /** Jump progressBar Steps */

    selectDots = (index) => {
        const {resetAll, setProgressBarIndex} = this.props;
        if (index === PROGRESS_BAR.STEP1) {
            resetAll();
        }
        setProgressBarIndex(index);
    };

    render() {
        const {t, progressBarIndex, completedIndex, kind, bulbs, technologies, products} = this.props;
        return (
            <Suspense fallback="loading">
                <div className="container">
                    <div className={"App row " + this.props.loadingStateClass}>
                        <header className="App-header">
                            <div className="img-txt-wrapper full-size">
                                <img
                                    className="img-responsive lazyloaded App-header__picture"
                                    src="https://look.ams-osram.com/transform/74c6e6e7-a99d-42d4-addc-31452c26f30f/333800_Automotive_Street_spiral-getty-121151515?io=transform:crop,height:908,width:2500,path:square&focuspoint=0.5,0.75"
                                    alt={t("app.title")}
                                    title={t("app.title")} />
                                <div className="text-wrapper top left textvis-onimage">
                                    <h2 className="font-headline-page white App-header__headline">{t("app.title")}</h2>
                                </div>
                            </div>
                            <p className="app-seo-first-text">{t("app.firstSeoText")}</p>
                            <p className="app-seo-second-text">{t("app.secondSeoText")}</p>
                        </header>
                        {!kind ?
                            <Vehicle />
                            :
                            <>
                                <div className="App-intro">
                                    <ProgressBar
                                        selectDots={this.selectDots}
                                        activeIndex={progressBarIndex}
                                        completedIndex={completedIndex}
                                    />
                                    <div className="find-info-hint">
                                        <h4>{t("app.replacementBulbTitle")}</h4>
                                        <p>{t("app.replacementBulbDescription")}</p>
                                    </div>
                                </div>
                                <div className="col-md-6 steps-panel-container">
                                    <div className="row">
                                        <div className="coal-md-12 steps-panel-container-inner">
                                            <span className={"progress-marker" + (progressBarIndex === 0 ? " progress-marker-current" : "")
                                            + (completedIndex > 0 ? " progress-marker-complete" : "")}
                                                onClick={() => {
                                                    sendEtrackerChangeEvent('0');
                                                    if (progressBarIndex !== 0) {
                                                        this.props.setProgressBarIndex(0);
                                                    }
                                                }}>1</span>
                                            <Brand
                                                selectDots={this.selectDots}
                                            />
                                        </div>
                                        {(completedIndex === 1 && bulbs.length === 0) &&
                                        <p>{t("product.notFound")}</p>}
                                        {bulbs?.length > 0 &&
                                        <div className="coal-md-12 steps-panel-container-inner display">
                                            <span className={"progress-marker" + (progressBarIndex === 1 ? " progress-marker-current" : "")
                                            + (completedIndex > 1 ? " progress-marker-complete" : "")}
                                                onClick={() => {
                                                    sendEtrackerChangeEvent('1');
                                                    if (progressBarIndex !== 1) {
                                                        this.props.setProgressBarIndex(1);
                                                    }
                                                }}>2</span>
                                            <Bulb
                                                selectDots={this.selectDots}
                                            />
                                        </div>}
                                        {technologies?.length > 0 &&
                                        <div className="caol-md-12 steps-panel-container-inner display">
                                            <span className={"progress-marker" + (progressBarIndex === 2 ? " progress-marker-current" : "")
                                            + (completedIndex > 2 ? " progress-marker-complete" : "")}
                                                onClick={() => {
                                                    sendEtrackerChangeEvent('2');
                                                    if (progressBarIndex !== 2) {
                                                        this.props.setProgressBarIndex(2);
                                                    }
                                                }}>3</span>
                                            <Note
                                                selectDots={this.selectDots}
                                            />
                                        </div>}
                                        {products?.length > 0 &&
                                        <div className="caol-md-12 steps-panel-container-inner display">
                                            <span className={"progress-marker" + (progressBarIndex === 3 ? " progress-marker-current" : "")
                                            + (completedIndex >= 3 ? " progress-marker-complete" : "")}
                                                onClick={() => {
                                                    sendEtrackerChangeEvent('3');
                                                    if (progressBarIndex !== 3) {
                                                        this.props.setProgressBarIndex(3);
                                                    }
                                                }}>4</span>
                                            <Products />
                                        </div>}
                                        {(products?.length === 0 && this.props.queryParams.ean !== null) &&
                                        <div><Products /></div>}
                                    </div>
                                </div>
                                <ProductDescription />
                            </>
                        }
                    </div>
                </div>
            </Suspense>
        );
    }
}

const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    bulbs: getBulbs(state),
    kind: getKind(state),
    progressBarIndex: getProgressBarIndex(state),
    completedIndex: getCompletedIndex(state),
    technologies: getTechnologies(state),
    products: getProducts(state),
    loadingStateClass: getLoadingStateClass(state)
});

const mapDispatchToProps = dispatch => ({
    setQueryParams: (item) => dispatch(setQueryParams(item)),
    setKind: (kindId) => dispatch(setKind(kindId)),
    setProgressBarIndex: (index) => dispatch(setProgressBarIndex(index)),
    setCompletedIndex: (index) => dispatch(setCompletedIndex(index)),
    resetAll: () => dispatch(resetAll()),
    setLoadingStateClass: (loadingStateClass) => dispatch(setLoadingStateClass(loadingStateClass))
});

//export default withCookies(App);
const cookieWrap = withCookies(App);
export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(cookieWrap));
