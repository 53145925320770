import React, { Component } from 'react';
import PropTypes from "prop-types";
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { I18nextProvider } from 'react-i18next';

import i18n from "../i18n";

class CookieStorageIntlProvider extends Component {
    static propTypes = {
        cookies: instanceOf(Cookies).isRequired,
        children: PropTypes.node,
        initialLocale: PropTypes.string
    };

    render() {
        return (
            <I18nextProvider i18n={i18n}>
                {this.props.children}
            </I18nextProvider>  
        );
    }
}

export default withCookies(CookieStorageIntlProvider);
