import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

import {reducer} from "./reducers";

const classToPlainObjectMiddleWare = () => next => action => {
    const planeObjectAction = {
        type: action.type,
        payload: action.payload
    };
    next(planeObjectAction);
};

const bindMiddleware = middleware => {
    if (process.env.NODE_ENV !== 'production') {
        return composeWithDevTools(applyMiddleware(...middleware))
    }
    return applyMiddleware(...middleware)
};

export const initStore = () => {
    return createStore(
        reducer,
        bindMiddleware([classToPlainObjectMiddleWare, thunk])
    )
};
