import API_HOST from "../constant/URL";
import {COOKIE_NAMES, DEFAULT_CONFIG, FALLBACK_LANG_API} from "../constant";
import {Cookies} from "react-cookie";

class ApiService {

  constructor() {
    // Set as default
    this.lang = FALLBACK_LANG_API;

    try {
      const cookies = new Cookies();
      const lang = cookies.get(COOKIE_NAMES.LANG) ?? DEFAULT_CONFIG;
      const {api_lang_code} = require(`../data/${lang}.json`);
      this.lang = api_lang_code;
    } catch (e) {
      console.error(e);
      this.lang = FALLBACK_LANG_API;
    }

    if (this.lang === undefined) {
      this.lang = FALLBACK_LANG_API;
    }
    console.log(this.lang);
  }

  fetchBrandBy = (lang, kind, year, model_id, variant_id) => {
    const yearParam = year !== null && year !== '' ? '&constructionyear=' + year : '';
    const variantParam = variant_id !== null ? '&variant_id=' + variant_id : '';
    const modelParam = model_id !== null ? '&model_id=' + model_id : '';
    return this.doFetch(`${API_HOST}/cars/lookups?kind_id=${kind}&lang=${this.lang}&lookuptype=manufacturers` + yearParam + variantParam + modelParam);
  };

  fetchModelBy = (lang, kind, brand_id, year) => {
    const brandParam = brand_id !== null ? '&manufacturer_id=' + brand_id : '';
    const yearParam = year !== null && year !== '' ? '&constructionyear=' + year : '';
    return this.doFetch(`${API_HOST}/cars/lookupmodelvariant?kind_id=${kind}&lookuptype=models&lang=` + encodeURIComponent(this.lang) + brandParam + yearParam);
  }

  fetchYearBy = (lang, brand_id, model_id, variant_id) => {
    const brandParam = brand_id !== null ? '&manufacturer_id=' + brand_id : '';
    const variantParam = variant_id !== null ? '&variant_id=' + variant_id : '';
    const modelParam = model_id !== null ? '&model_id=' + model_id : '';
    return this.doFetch(`${API_HOST}/cars/lookups?lookuptype=years&lang=` + encodeURIComponent(this.lang) + brandParam + variantParam + modelParam);
  };

  fetchTypeBy = (lang, model_id, variant_id, year) => {
    const variantParam = variant_id !== null ? '&variant_id=' + variant_id : '';
    const modelParam = model_id !== null ? '&model_id=' + model_id : '';
    const yearParam = year !== null && year !== '' ? '&constructionyear=' + year : '';
    return this.doFetch(`${API_HOST}/cars/lookuptypeinfo?lang=` + encodeURIComponent(this.lang) + variantParam + modelParam + yearParam);
  };

  fetchBulb = (lang, carID) => this.doFetch(`${API_HOST}/bulbs/positions?car_id=${encodeURIComponent(carID)}&lang=${encodeURIComponent(this.lang)}`);

  fetchTechnology = (lang, carID, selectedBulb) => this.doFetch(`${API_HOST}/bulbs/technologies?car_id=${encodeURIComponent(carID)}&use_id=${encodeURIComponent(selectedBulb)}&lang=${encodeURIComponent(this.lang)}`);

  fetchPillar = (lang, carID, selectedBulb, technology) => this.doFetch(`${API_HOST}/bulbs/pillars?car_id=${encodeURIComponent(carID)}&use_id=${encodeURIComponent(selectedBulb)}&technology_id=${encodeURIComponent(technology)}&lang=${encodeURIComponent(this.lang)}`);

  fetchDescription = (lang, ean) => this.doFetch(`${API_HOST}/bulbs/product_info?ean=${encodeURIComponent(ean)}&lang=${encodeURIComponent(this.lang)}`);

  fetchCarByCarid = (lang, car_id) => this.doFetch(`${API_HOST}/cars/info?car_id=${car_id}&lang=${encodeURIComponent(this.lang)}`);

  fetchByPartNumber = (lang, partNumber, kind) => this.doFetch(`${API_HOST}/bulbs/pillarsbyproduct?kind_id=${kind}&search=${partNumber.toUpperCase()}&lang=${encodeURIComponent(this.lang)}`);

  fetchByKba = (kba, lang) => this.doFetch(`${API_HOST}/cars/info?kba_num=${kba}&lang=${encodeURIComponent(this.lang)}`);

  fetchBuyNow = (ean, lang, cache = false) => {
    if (!ean && !lang) {
      return;
    }
    return this.doFetch(`${API_HOST}/bulbs/buy_now?lang=${encodeURIComponent(this.lang)}&ean=${encodeURIComponent(ean)}&cache=${encodeURIComponent(cache)}`);
  };

  fetchByHsn = (hsn, lang) => {
    if (!hsn) {
      return;
    }
    return this.doFetch(`${API_HOST}/cars/checkhsn?lang_code=${encodeURIComponent(this.lang)}&hsn=${encodeURIComponent(hsn)}`)
  }

  // Does the actual fetching and returns null if the response is bad.
  doFetch = (url) => {
    return fetch(url)
      .then(response => {
        if (response.status === 200) {
          return response.json();
        } else {
          console.error("fetch received bad response", url, response);
          return null;
        }
      })
      .catch(err => this.handleError("Unable to perform fetch from " + url, err, url));
  };

  handleError = (message, err, url = '') => {
    console.error("Unable to retrieve data", message, err);
    return {status: 'error', message, url, err}
  };

}

export const apiService = new ApiService();
