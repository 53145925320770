import React from "react";
import PropTypes from "prop-types";
import {MEDIA_ENDPOINT} from "../../constant";

class ImageGallery extends React.Component {
    static propTypes = {
        assets: PropTypes.array.isRequired
    }

    constructor(props) {
        super(props);
        this.carouselRef = React.createRef();
        this.previewsRef = React.createRef();
        this.zoomRef = React.createRef();

        this.state = {
            eventListener: null,
            modal: false,
            modalZoom: false,
            offset: 0
        };
        this.touchStartX = null;
        this.touchStartY = null;
        this.lastTouchTimestamp = 0;
        this.mouseStartX = null;
        this.mouseStartY = null;
        this.mouseDownTimestamp = 0;
        this.mouseStepX = null;
        this.mouseStepY = null;
        this.mouseFirstStep = true;
        this.skipClick = false;
        this.AssetTypeBeautyShot = 'ref_pim_amsp_productbeautyshotsimages';
        this.BeautyShotCropParameter = 'c,x,1230,y,827,w,2492,h,2492';
        this.ImageSizeThumb = '60';
        this.ImageSizeModal = '640';
    }

    carouselOnclick = () => {
        if (!this.skipClick) {
            if (!this.state.modal) {
                this.toggleModal();
            } else {
                this.toggleModalZoom();
            }
        } else {
            this.skipClick = false;
        }
    }

    _onMouseDown = () => {
        this.mouseStepX = null;
        this.mouseStepY = null;
        this.mouseFirstStep = true;
    }

    _onMouseMove = (e) => {
        // console.log('mousemove', e, e.button, e.buttons);
        if (this.state.modalZoom && e.buttons === 1) {
            this.skipClick = true;
            // console.log('skip on');
            if (this.mouseFirstStep === true) {
                this.mouseFirstStep = false;
                // console.log('step -1', e.clientX, e.clientY);
            } else if (this.mouseStepX === null && this.mouseStepY === null) {
                this.mouseStepX = e.clientX;
                this.mouseStepY = e.clientY;
                // console.log('step null', e.clientX, e.clientY);
            } else if (e.clientX === 0 && e.clientY === 0) {
                this.mouseStepX = null;
                this.mouseStepY = null;
                // console.log('step 0 value', e.clientX, e.clientY);
            } else {
                // console.log(this.zoomRef.current);
                const traveledX = this.mouseStepX - e.clientX;
                const traveledY = this.mouseStepY - e.clientY;
                this.mouseStepX = e.clientX;
                this.mouseStepY = e.clientY;
                this.zoomRef.current.scrollLeft = this.zoomRef.current.scrollLeft + traveledX;
                this.zoomRef.current.scrollTop = this.zoomRef.current.scrollTop + traveledY;
                // console.log('drag', e.clientX, e.clientY, traveledX, traveledY);
            }
        } else {
            this.skipClick = false;
            // console.log('skip off');
        }
    }

    _onTouchStart = (e) => {
        this.lastTouchTimestamp = Date.now();
        this.touchStartX = e.changedTouches[0].clientX;
        this.touchStartY = e.changedTouches[0].clientY;
    }
    
    _onTouchMove = (e) => {
        const touchMovePx = Math.round(e.changedTouches[0].clientX - this.touchStartX);
        if (!this.state.modalZoom && !this.state.modal) {
            this.setMarginByRef(this.state.offset, touchMovePx);
        }
    }
    
    _onTouchEnd = (e) => {
        const touchEndX = e.changedTouches[0].clientX;
        if (!this.state.modalZoom) {
            // console.log('touchend' ,e);

            const travelDistance = Math.abs(touchEndX - this.touchStartX);
            if (travelDistance > 10) {
                if (touchEndX < this.touchStartX) {
                    this.next();
                } else {
                    this.previous();
                }
            }
        }
        // console.log('touchend', e.changedTouches[0].clientX);
    }

    setMarginByRef = (offset, touchMovementPx) => {
        let style = '';
        if (touchMovementPx === null || typeof touchMovementPx !== 'number') {
            style = `-${offset * 100}%`;
        } else {
            const marginPercent = offset * 100;
            style = `calc(-${marginPercent}% + ${touchMovementPx}px)`;
        }
        // console.log('style', style);
        this.carouselRef.current.style.marginLeft = style;
    }

    // Using React ref, scroll child at index i into view.
    scrollChildIntoViewByRef = (ref, i) => {
        const element = ref.current;
        const child = ref.current.children[i];
        const childWidth = child.offsetWidth;
        const childPosition = child.offsetLeft;
        const elementScrollPosition = element.scrollLeft;
        const elementWidth = element.offsetWidth;
        if (childPosition < elementScrollPosition) {
            element.scrollLeft = childPosition;
        } else if (childPosition + childWidth > elementScrollPosition + elementWidth) {
            element.scrollLeft = childPosition - childWidth;
        }
    }

    toggleModal = () => {
        if (this.state.modal) {
            this.setState({modalZoom: false});
        }
        this.setState({
            modal: !this.state.modal
        });
    }

    toggleModalZoom = () => {
        this.setState({modalZoom: !this.state.modalZoom});
    }

    next = () => {
        // console.log('next', this.state.offset);
        if (this.state.offset < (this.props.assets.length - 1)) {
            this.setMarginByRef(this.state.offset + 1, null);
            // this.previewsRef.current.children[this.state.offset + 1].
            this.scrollChildIntoViewByRef(this.previewsRef, this.state.offset + 1);
            this.setState({
                offset: this.state.offset + 1,
                modalZoom: false
            });
        } else {
            this.setMarginByRef(this.state.offset, null);
        }
    }
    
    previous = () => {
        if ((this.state.offset -1) >= 0) {
            this.setMarginByRef(this.state.offset - 1, null);
            this.scrollChildIntoViewByRef(this.previewsRef, this.state.offset - 1);
            this.setState({
                offset: this.state.offset - 1,
                modalZoom: false
            });
        } else {
            this.setMarginByRef(this.state.offset, null);
        }
    }

    selectIndex = (i) => {
        this.scrollChildIntoViewByRef(this.previewsRef, i);
        this.setState({
            offset: i,
            modalZoom: false
        });
        this.setMarginByRef(i, null);
    }

    getImageUrlFromAsset = (asset, size) => {
        if (typeof asset === 'object' && typeof asset.dam_id === 'string' && typeof asset.type === 'string') {
            if (typeof size === 'string') {
                size = `s,x,${size},y,0/`;
            } else {
                size = '';
            }
            let crop = '';
           /* if (asset.type === this.AssetTypeBeautyShot) {
                crop = this.BeautyShotCropParameter + '/';
                const bynderUrl = asset.bynderurl;
                return bynderUrl + `?io=transform:crop,height:1000,width:1000,path:square&io=transform:fill,width:60&focuspoint=0.5,0.45`;
            } */
            // return MEDIA_ENDPOINT + `/im/img/${asset.dam_id}/${crop}${size}f,j/image.jpg`;
            return asset.bynderurl;
        } else {
            console.error('Bad asset provided for product image', asset);
        }
    }

    render() {
        if (this.props.assets) {
            return (
                <>
                    <div className={"image-gallery-outer" + (this.state.modal ? ' modalex' : '') + (this.state.modalZoom ? ' modalzoom' : '')}>
                        <div className="modalex-toggle ms-icon_close" onClick={() => {this.toggleModal()}}></div>
                        <div className="modalex-toggle-loupe ms-icon_loupe" onClick={() => {this.toggleModal()}}></div>
                        <div className="carousel-counter">{`${this.state.offset + 1} / ${this.props.assets.length}`}</div>
                        <div className="carousel-outer"
                            onTouchStart={(ev) => this._onTouchStart(ev)}
                            onTouchMove={(ev) => this._onTouchMove(ev)}
                            onTouchEnd={(ev) => this._onTouchEnd(ev)}
                            onMouseDown={(ev) => this._onMouseDown(ev)}
                            onMouseMove={(ev) => this._onMouseMove(ev)}
                            onClick={this.carouselOnclick}
                            id="carousel"
                            >
                            <div className="carousel-inner" ref={this.zoomRef}>
                                {this.props.assets.map((asset, i) => {
                                    if (i === 0) {
                                        return (
                                            <div className={"carousel-image"  + (this.state.offset === i ? ' selected' : '')} ref={this.carouselRef} key={i}>
                                                <img className="carousel-image-regular" alt={asset.type} src={this.getImageUrlFromAsset(asset, this.ImageSizeModal)}></img>
                                                <img className="carousel-image-zoom" alt={asset.type} src={this.getImageUrlFromAsset(asset)} loading="lazy"></img>
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div className={"carousel-image"  + (this.state.offset === i ? ' selected' : '')} key={i}>
                                                <img className="carousel-image-regular" alt={asset.type} src={this.getImageUrlFromAsset(asset, this.ImageSizeModal)}></img>
                                                <img className="carousel-image-zoom" alt={asset.type} src={this.getImageUrlFromAsset(asset)} loading="lazy"></img>
                                            </div>
                                        );
                                    }
                                })}
                            </div>
                        </div>
                        <div className="carousel-buttons">
                            <div style={{"display": (this.state.offset > 0 ? '' : ' none')}} className="carousel-left" onClick={() => this.previous()}></div>
                            <div style={{"display": (this.state.offset < (this.props.assets.length - 1) ? '' : ' none')}} className="carousel-right" onClick={() => this.next()}></div>
                            <div></div>
                        </div>
                        <div className="carousel-previews-outer">
                            <div className="carousel-previews" ref={this.previewsRef}>
                                {this.props.assets.map((asset, i) => {
                                    return (
                                        <div className={"carousel-preview-image" + (this.state.offset === i ? ' selected' : '')}  key={i} onClick={() => this.selectIndex(i)}>
                                            <img src={this.getImageUrlFromAsset(asset, this.ImageSizeThumb)} alt={asset.type}></img>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="modalx-backdrop"></div>
                </>
            );
        } else {
            console.error('Bad assets object for image gallery', this.props.assets);
            return (null);
        }

    }

}

export default ImageGallery;
