import {apiService} from "../../service/Apiservice";

/**
 *
 * @param HSN
 * @param configLang
 * @returns {Promise<*|{err: *, message: *, url: string, status: string}>}
 */
export const fetchByHSN = async (HSN, configLang) => {
  try {
    const response = await apiService.fetchByHsn(HSN, configLang);
    if (response?.status && response.status === 'error') {
      throw new Error(response.message);
    }
    return response;
  } catch (e) {
    throw new Error(`${e.message}, fetchByHSN`);
  }
}