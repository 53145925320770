export const ActionTypes = {
    SET_INPUT_FIELD_VALUE: "SET_INPUT_FIELD_VALUE",
    SET_INPUT_FIELD_ERROR: "SET_INPUT_FIELD_ERROR",
    SET_QUERY_PARAMS: "SET_QUERY_PARAMS",
    SET_DROPDOWN_FIELD_VALUES: "SET_DROPDOWN_FIELD_VALUES",
    SET_BULB_VALUES: "SET_BULB_VALUES",
    SET_TECHNOLOGY_VALUES:"SET_TECHNOLOGY_VALUES",
    SET_KIND: "SET_KIND",
    SET_PROGRESSBAR_INDEX:"SET_PROGRESSBAR_INDEX",
    SET_COMPLETED_INDEX:"SET_COMPLETED_INDEX",
    SET_PRODUCTS: "SET_PRODUCTS",
    SET_SHOW_VEHICLE_PICKER: "SET_SHOW_VEHICLE_PICKER",
    SET_PRODUCT_DESCRIPTION: "SET_PRODUCT_DESCRIPTION",
    RESET_ALL: "RESET_ALL",
    SET_LOADING_STATE_CLASS: "SET_LOADING_STATE_CLASS"
};

export const setInputFieldValue = (inputName, inputValue) => ({
    type: ActionTypes.SET_INPUT_FIELD_VALUE,
    payload: {
        inputName,
        inputValue
    }
});

export const setInputFieldError = (inputName, hasError) => ({
    type: ActionTypes.SET_INPUT_FIELD_ERROR,
    payload: {
        inputName,
        hasError
    }
});


export const setQueryParams = (items) => ({
    type: ActionTypes.SET_QUERY_PARAMS,
    payload: {
        ...items
    }
});

export const setDropdownFieldValue = (items) => ({
    type: ActionTypes.SET_DROPDOWN_FIELD_VALUES,
    payload: {
        ...items
    }
});

export const setBulbValues = (bulbs) => ({
    type: ActionTypes.SET_BULB_VALUES,
    payload: [
        ...bulbs
    ]
});

export const setTechnologyValues = (technologies) => ({
    type: ActionTypes.SET_TECHNOLOGY_VALUES,
    payload: [
        ...technologies
    ]
});

export const setProducts = products => ({
    type: ActionTypes.SET_PRODUCTS,
    payload: [
        ...products
    ]
});

export const setKind = (kindId) => ({
    type: ActionTypes.SET_KIND,
    payload:{
        kindId
    }
});

export const setProgressBarIndex = (index) => ({
    type: ActionTypes.SET_PROGRESSBAR_INDEX,
    payload: {
        index
    }
});

export const setCompletedIndex = (index) => ({
    type: ActionTypes.SET_COMPLETED_INDEX,
    payload: {
        index
    }
});

export const setProductDescription = description => ({
    type: ActionTypes.SET_PRODUCT_DESCRIPTION,
    payload: description
});

export const resetAll = () => ({
    type: ActionTypes.RESET_ALL
});

export const setLoadingStateClass = (loadingStateClass) => ({
    type: ActionTypes.SET_LOADING_STATE_CLASS,
    payload: loadingStateClass
});
