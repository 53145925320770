import React, {Component} from "react";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Moment from "moment";
import {apiService} from "../service/Apiservice";
import debounce from "lodash/debounce";
import {withTranslation} from "react-i18next";
import DropdownGroup from "./Dropdown/DropdownGroup";
import InputGroup from "./InputGroup/InputGroup";
import Search from "../composition/Search/Search";
import {Modal} from "react-bootstrap";

import {
    BRAND_DROPDOWNS,
    BRAND_OVERVIEW_ORDER_ATTRIBUTE,
    BRAND_OVERVIEW_ORDER_SORT,
    DROPDOWN_OBJECT_KEY_LABEL,
    DROPDOWN_OPJECT_KEY,
    PROGRESS_BAR,
    QueryKeys
} from "../constant";
import {
    setBulbValues,
    setCompletedIndex,
    setDropdownFieldValue,
    setInputFieldError,
    setInputFieldValue,
    setProducts,
    setProgressBarIndex,
    setQueryParams,
    setLoadingStateClass
} from "../redux/actions";
import {
    formatObjectArray,
    formatObjectArrayModelLabel,
    formatObjectArrayTypes,
    getCurrentDropdownIndex
} from "../util/utilFkt";
import {getKind, getProgressBarIndex, getQueryParams} from "../redux/selectors";
import CollapsePanel from "./CollapsePanel/CollapsePanel";
import orderBy from "lodash/orderBy";

import { sendEtrackerClickEvent, sendEtrackerInputEvent, formatYear } from "../util/utilFkt";

class Brand extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        selectDots: PropTypes.func.isRequired,
        queryParams: PropTypes.object.isRequired,
        kind: PropTypes.number.isRequired,
        activeIndex: PropTypes.number.isRequired,
        setInputFieldValue: PropTypes.func.isRequired,
        setQueryParams: PropTypes.func.isRequired,
        setProgressBarIndex: PropTypes.func.isRequired,
        setInputFieldError: PropTypes.func.isRequired,
        setBulbValues: PropTypes.func.isRequired,
        setCompletedIndex: PropTypes.func.isRequired,
        setProducts: PropTypes.func.isRequired,
        setDropdownFieldValue: PropTypes.func.isRequired,
        setLoadingStateClass: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        /** Import Field Data */
        const fieldDataImport = require(`../data/${this.props.queryParams.configLang}.json`);

        /** Init DropdownFields*/
        const sortedDropdownFields = orderBy(fieldDataImport.dropdownFields, [BRAND_OVERVIEW_ORDER_ATTRIBUTE], [BRAND_OVERVIEW_ORDER_SORT]);
        const dropDownFields = sortedDropdownFields.map(((element, index) => {
            return {
                titleId: element.titleId,
                selectId: element.selectId,
                name: element.name,
                brandOverviewPos: element.brandOverviewPos,
                disabled: index !== 0,
                items: [],
                currentItem: "",
                currentIndex: null
            };
        }));

        const dropdownRefs = [];
        dropDownFields.forEach(() => {
            dropdownRefs.push(React.createRef());
        });

        this.state = {
            dropdownFields: dropDownFields,
            inputFields: fieldDataImport.inputFields,
            searchDescription: null,
            dropdownRefs: dropdownRefs,
            showSearchModalMultiSelect: false,
            modalKbaData: null
        };
    }

    componentDidMount() {

        const {queryParams} = this.props;

        //Init Data (Brand)
        this.initData().then(() => {
            this.resolveQueryFromUrl(queryParams);
        });
    }

    getDropdownNameFromFieldName = (fieldName) => {
        switch (fieldName) {
            case "year":
                return BRAND_DROPDOWNS.YEAR;
            case "brand":
                return BRAND_DROPDOWNS.BRAND;
            case "model":
                return BRAND_DROPDOWNS.MODEL;
            case "variant":
                return BRAND_DROPDOWNS.MODEL;
            case "type":
                return BRAND_DROPDOWNS.TYPE;
            default:
                return null;
        }
    };

    // Checks if the field belongs to a dropdown above or below current
    // and returns selected value if above or null if below.
    // Allows filling dropdowns top-down with only most neccessary parameters
    // even if more are available. This gives the user the same options as if 
    // filling dropdowns manually if he wishes to change selection in one
    // of the dropdowns after they were pre-filled from queryParams.
    getValueOrNull = (currentIndex, fieldName, value) => {
        const dropdownName = this.getDropdownNameFromFieldName(fieldName);
        const dropdownIndex = this.state.dropdownFields.findIndex(element => {
            return element.name === dropdownName;
        });
        if (currentIndex < dropdownIndex) {
            return null;
        }
        else {
            return value;
        }
    };


    // Automatically fills all dropdowns recursively. This is meant to be
    // used with queryParams.
    fillNextDropdown = async (index, brand, model, year, variant, type) => {
        const nextIndex = index + 1;
        // let nextDropdown = this.state.dropdownFields[nextIndex]?.name;
        let nextDropdown = this.state.dropdownFields[index]?.name;
        const yearParam = this.getValueOrNull(index, "year", year);
        const modelParam = this.getValueOrNull(index, "model", model);
        const brandParam = this.getValueOrNull(index, "brand", brand);
        const typeParam = this.getValueOrNull(index, "type", variant);

        switch (nextDropdown) {
            case BRAND_DROPDOWNS.BRAND:
                return this.loadBrandData(index, yearParam, modelParam, null)
                .then(() => {
                    const selectedIndex = this.state.dropdownFields[index].items.findIndex(element => element.id === brand);
                    if (selectedIndex !== -1) {
                        const selectedItem = this.state.dropdownFields[index].items[selectedIndex];
                        this.promiseSetCurrentItem(index, selectedIndex, selectedItem)
                        .then(() => {
                            this.fillNextDropdown(nextIndex, brand, model, year, variant, type);
                        });
                    }
                });
            case BRAND_DROPDOWNS.MODEL:
                return this.loadModelData(index, brandParam, yearParam).then(() => {
                    const selectedIndex = this.state.dropdownFields[index].items.findIndex(element => element.model_id === model);
                    if (selectedIndex !== -1) {
                        const selectedItem = this.state.dropdownFields[index].items[selectedIndex];
                        this.promiseSetCurrentItem(index, selectedIndex, selectedItem).then(() => {
                            this.fillNextDropdown(nextIndex, brand, model, year, variant, type);
                        });
                    }
                });
            case BRAND_DROPDOWNS.YEAR:
                return this.loadYearData(index, brandParam, modelParam, typeParam)
                .then(() => {
                    const selectedIndex = this.state.dropdownFields[index].items.findIndex(element => element.name === year);
                    if (selectedIndex !== -1) {
                        const selectedItem = this.state.dropdownFields[index].items[selectedIndex];
                        this.promiseSetCurrentItem(index, selectedIndex, selectedItem).then(() => {
                            this.fillNextDropdown(nextIndex, brand, model, year, variant, type);
                        });
                    }
                });
            case BRAND_DROPDOWNS.TYPE:
                return this.loadTypeData(index, modelParam, typeParam, yearParam)
                .then(() => {
                    const selectedIndex = this.state.dropdownFields[index].items.findIndex(element => element.id === type);
                    if (selectedIndex !== -1) {
                        const selectedItem = this.state.dropdownFields[index].items[selectedIndex];
                        this.promiseSetCurrentItem(index, selectedIndex, selectedItem).then(() => {
                            this.fillNextDropdown(nextIndex, brand, model, year, variant, type);
                        });
                    }
                });
            default:
                const selectedIndex = this.state.dropdownFields[index - 1].items.findIndex(element => element.id === type);
                return this.handleChangeType(type, index, selectedIndex);
        }
    };


    resolveQueryFromUrl = async (dropDowns) => {
        this.state.inputFields.forEach((field) => {
            if (this.props.queryParams[field.queryKey]) {
                this.props.setInputFieldValue(field.queryKey, this.props.queryParams[field.queryKey]);
            }
        });
        // Report deep link to etracker.
        if (this.props.queryParams.ean !== null) {
            sendEtrackerClickEvent("Entries by share link", "ean");
        }
        // If url parameters processed in App.js already contain car_id close the
        // car input and load bulbs immediately.
        if (this.props.queryParams.car !== null) {
            this.loadCarDataByCarId(this.props.queryParams.car);
        } else if (this.props.queryParams.ean !== null && this.props.queryParams.part === null) {
            this.loadProductByEan();
        }
        else if ((dropDowns.brand || dropDowns.model || dropDowns.year || dropDowns.variant || dropDowns.type)) {
            this.fillNextDropdown(0, dropDowns.brand, dropDowns.model, dropDowns.year, dropDowns.variant, dropDowns.type);
        } else {
            // Checks all Query Params Matching to input Fields & resolve themn
            this.state.inputFields.forEach((field) => {
                if (this.props.queryParams[field.queryKey]) {
                    this.delayedFetch(field.queryKey, this.props.queryParams[field.queryKey]);
                    this.props.setInputFieldValue(field.queryKey, this.props.queryParams[field.queryKey]);
                }
            });
        }
    };

    loadProductByEan = (ean) => {
        this.props.setCompletedIndex(PROGRESS_BAR.STEP4);
        this.props.selectDots(PROGRESS_BAR.STEP4);
    };

    handleInputFieldChange = event => {
        this.props.setQueryParams({
            ean: null,
            car: null,
            bulb: null,
            brand: null,
            model: null,
            part: null,
            technology: null,
            type: null,
            variant: null,
            year: null
        });
        let {target} = event;
        if (typeof target === 'undefined') {
            target = event;
        }
        sendEtrackerInputEvent(target.name, target.value);
        this.props.setInputFieldValue(target.name, target.value);
        if (target.value !== "") {
            this.delayedFetch(target.name, target.value);
        }
    };

    createCarDescription = (responseObject) => {
        const yearEnd = `${responseObject?.year_end?.length ? Moment(responseObject?.year_end)
        .format("YYYY") : ""}`;
        return `${yearEnd} ${responseObject?.manufacturer_name} 
                        ${responseObject?.model_name} - ${responseObject.variant_name} 
                        ${responseObject?.type_name} ${responseObject?.type_kw}kw 
                        (${responseObject?.year_start ? Moment(responseObject?.year_start).format(("YYYY")) : ""} - ${yearEnd})`;
    };

    delayedFetch = debounce((inputName, inputValue, persistCookie) => this.fetchWithInputField(inputName, inputValue, persistCookie), 1000);
    
    fetchWithInputField = (inputName, inputValue) => {
        //On Change sets ProgressBar index to first step
        this.props.setProgressBarIndex(PROGRESS_BAR.STEP1);
        this.props.setQueryParams({[inputName]: inputValue});

        // Reset all error message
        this.props.setInputFieldError(inputName, false);

        let apiCall;

        switch (inputName) {
            case QueryKeys.KBA:
                this.props.setLoadingStateClass("loading-brand");
                apiCall = apiService.fetchByKba(inputValue, this.props.queryParams.lang);
                break;
            case QueryKeys.PART:
                this.props.setLoadingStateClass("loading-brand");
                apiCall = apiService.fetchByPartNumber(this.props.queryParams.lang, inputValue, this.props.kind);
                break;
            default:
                return;
        }
        apiCall.then(response => {
            this.props.setLoadingStateClass("");
            const responseLength = response ? Object.keys(response).length : 0;

            if (response === null || (responseLength === 0 && inputName === QueryKeys.PART)) {
                this.props.setInputFieldError(inputName, true);
            } else if ((responseLength !== 1 && inputName === QueryKeys.KBA)) {
                this.setState({
                    showSearchModalMultiSelect: true,
                    modalKbaData: response
                })
            }
            else {
                const responseObject = {...response[0]};
                let searchDescription = inputValue;
                switch (inputName) {
                    case QueryKeys.KBA:
                        const carID = responseObject.car_id;
                        searchDescription = this.createCarDescription(responseObject);
                        const bulbPositions = apiService.fetchBulb(this.props.queryParams.lang, carID);
                        bulbPositions.then(response => {
                            if (response) {
                                // reset dropdowns when bulbs found
                                this.resetSubSequentDropdowns(-1);
                                this.props.setBulbValues(response);

                            }

                            this.props.setQueryParams({car: carID});
                            // update completed Index
                            this.props.setCompletedIndex(PROGRESS_BAR.STEP2);
                            this.props.selectDots(PROGRESS_BAR.STEP2);

                        });
                        break;
                    case QueryKeys.PART:
                        this.props.setProducts(response);
                        // reset dropdowns when part found
                        this.resetSubSequentDropdowns(-1);
                        // update completed Index
                        this.props.setCompletedIndex(PROGRESS_BAR.STEP4);
                        this.props.selectDots(PROGRESS_BAR.STEP4);
                        break;
                    default:
                }

                this.setState(prevState => ({
                    ...prevState.inputErrors,
                    searchDescription: searchDescription,
                }));
                this.props.setInputFieldError(inputName, false);
            }
        });
    };

    showModal () {
        return (
          <Modal
            show={this.state.showSearchModalMultiSelect}
            onHide={()=> {
                this.setState({ showSearchModalMultiSelect: false})
            }}
          >
              <Modal.Header closeButton>
                  <Modal.Title id="contained-modal-title">
                      {this.props.t('brand.modalMultiOptions')}
                  </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                  {this.state.modalKbaData.map((o) => {
                      return (
                        <ul key={o.car_id} className="Search__default-ul">
                            <li className="Search__default-li" onClick={()=> {
                                const carID = o.car_id;
                                const searchDescription = this.createCarDescription(o);
                                const bulbPositions = apiService.fetchBulb(this.props.queryParams.lang, carID);
                                bulbPositions.then(response => {
                                    if (response) {
                                        // reset dropdowns when bulbs found
                                        this.resetSubSequentDropdowns(-1);
                                        this.props.setBulbValues(response);
                                    }
                                    this.props.setQueryParams({car: carID});
                                    // update completed Index
                                    this.props.setCompletedIndex(PROGRESS_BAR.STEP2);
                                    this.props.selectDots(PROGRESS_BAR.STEP2);

                                });
                                this.setState(prevState => ({
                                    ...prevState.inputErrors,
                                    searchDescription: searchDescription,
                                    showSearchModalMultiSelect: false
                                }));
                                this.props.setInputFieldError(QueryKeys.KBA, false);
                            }}>{formatYear(o.year_end)} {o.manufacturer_name} {o.model_name} - {o.variant_name} {o.type_name} {o.type_kw} {o.kind_name} ({formatYear(o.year_start)} - {formatYear(o.year_end)})</li>
                        </ul>)
                  })}
              </Modal.Body>
          </Modal>
        )
    }

    /** Inititalize First Dropdown*/

    initData() {
        const firstDropdownType = this.state.dropdownFields[0]?.name;
        switch (firstDropdownType) {
            case BRAND_DROPDOWNS.BRAND:
                return this.loadBrandData(0, null, null, null);
            case BRAND_DROPDOWNS.MODEL:
                return this.loadModelData(0, null, null);
            case BRAND_DROPDOWNS.YEAR:
                return this.loadYearData(0, null, null, null);
            case BRAND_DROPDOWNS.TYPE:
                console.error("Type should not be the first dropdown");
                return null;
            default:
                console.error("Bad first dropdown.");
                return null;
        }
    }

    getSelectedYear() {
        let year = null;
        this.state.dropdownFields.forEach(element => {
            if (element.name === BRAND_DROPDOWNS.YEAR) {
                year = element.currentItem?.name;
            }
        });
        if (typeof year === "undefined" || year === "") {
            year = null;
        }
        return year;
    }

    getSelectedModelId() {
        let model = null;
        this.state.dropdownFields.forEach(element => {
            if (element.name === BRAND_DROPDOWNS.MODEL) {
                model = element.currentItem?.model_id;
            }
        });
        if (typeof model === "undefined") {
            model = null;
        }
        return model;
    }

    getSelectedVariantId() {
        let variant = null;
        this.state.dropdownFields.forEach(element => {
            if (element.name === BRAND_DROPDOWNS.MODEL) {
                variant = element.currentItem?.variant_id;
            }
        });
        if (typeof variant === "undefined") {
            variant = null;
        }
        return variant;
    }

    getSelectedBrandId() {
        let brand = null;
        this.state.dropdownFields.forEach(element => {
            if (element.name === BRAND_DROPDOWNS.BRAND) {
                brand = element.currentItem?.id;
            }
        });
        if (typeof brand === "undefined") {
            brand = null;
        }
        return brand;
    }

    loadYearData(index, brand_id, model_id, variant_id) {
        this.setDropdownLoadingState(index, true);
        return apiService.fetchYearBy(this.props.queryParams.lang, brand_id, model_id, variant_id)
        .then(response => {
            this.setDropdownLoadingState(index, false);
            if (response) {
                this.setItems(index, formatObjectArray(response, DROPDOWN_OPJECT_KEY), true);
                return null;
            }
        });
    }

    loadBrandData(index, year, model_id, variant_id) {
        this.setDropdownLoadingState(index, true);
        return apiService.fetchBrandBy(this.props.queryParams.lang, this.props.kind, year, model_id, variant_id)
        .then(response => {
            this.setDropdownLoadingState(index, false);
            if (response) {
                this.setItems(index, response, true);
            }
            else {
                console.error("Response received without brands");
            }
        });
    }

    loadModelData(index, brand_id, year) {
        this.setDropdownLoadingState(index, true);
        return apiService.fetchModelBy(this.props.queryParams.lang, this.props.kind, brand_id, year)
        .then(response => {
            this.setDropdownLoadingState(index, false);
            if (response) {
                const newitems = formatObjectArrayModelLabel(response, DROPDOWN_OBJECT_KEY_LABEL);
                this.setItems(index, newitems, true);
                return null;
            }
        });
    }

    loadTypeData(index, model_id, variant_id, year) {
        // this.props.setQueryParams({year: year});
        this.setDropdownLoadingState(index, true);
        return apiService.fetchTypeBy(this.props.queryParams.lang, model_id, variant_id, year)
        .then(response => {
            this.setDropdownLoadingState(index, false);
            if (response) {
                this.setItems(index, formatObjectArrayTypes(response, DROPDOWN_OPJECT_KEY), true);
                return null;
            }
        });
    }

    // Load car data by car_id to display car name in panel selected option,
    // then fetch bulb by car_id.
    loadCarDataByCarId(car_id) {
        this.props.setLoadingStateClass("loading-brand");
        return apiService.fetchCarByCarid(this.props.queryParams.lang, car_id).then(response => {
            this.props.setLoadingStateClass("");
            if (response === null) {
                console.error("error loading cars", response);
            }
            else {
                // response returns an array of cars(?), for now always take the first car.
                const carDescription = this.createCarDescription(response[0]);
                this.setState({searchDescription: carDescription});
                this.props.setLoadingStateClass("loading-positions");
                apiService.fetchBulb(this.props.queryParams.lang, car_id)
                .then(resBulb => {
                    this.props.setLoadingStateClass("");
                    if (resBulb !== null) {
                        this.props.setBulbValues(resBulb);
                    }
                });
                this.lastDropdownSet(true);
            }
        });
    }

    setDropdownLoadingState(index, value) {
        this.setState(state => {
            return state.dropdownFields.map((item, j) => {
                if (j === index) {
                    item.loading = value;
                    return item;
                }
                else {
                    return null;
                }
            });
        });
    }

    promiseSetCurrentItem = (index, itemIndex, newItem) => new Promise(resolve => this.setState(state => {
        return state.dropdownFields.map((item, j) => {
            if (j === index && item.currentItem !== newItem) {
                item.currentItem = newItem;
                item.currentIndex = itemIndex;
                return item;
            }
            else {
                return null;
            }
        });
    }, resolve));

    // Reactjs setState runs asynchronously which can cause a race condition
    // if new state is required immediately. To avoid cluttering the code
    // with setState callbacks it is better to turn setState into a
    // self-resolving Promise which can be called synchronously by using 
    // await this.promiseResetSubsequentDropdowns(index);
    promiseResetSubsequentDropdowns = (index) => new Promise(resolve => this.setState(state => {
        state.dropdownFields.map((item, j) => {
            if (j > index) {
                item.disabled = true;
                item.currentItem = null;
                item.items = [];
                return item;
            }
            else {
                return null;
            }
        });
        return state;
    }, resolve));

    // Synchronous set state function.
    promisedSetState = (state) => new Promise(resolve => this.setState(state, resolve));

    /** Set item at current index for next dropdown field*/
    setItems = (index, newItems, resetSubsequent) => {
        let autofilled = false;
        let reset = false;
        if (typeof resetSubsequent === "boolean" && resetSubsequent) {
            reset = true;
        }
        this.setState(state => {
            return state.dropdownFields.map((item, j) => {

                if (j === index && newItems.length > 0) {
                    //first reset items
                    item.items = [];
                    //enable dropdown
                    item.disabled = false;
                    //fill items
                    newItems.forEach((element) => {
                        item.items.push(element);
                    });
                    // If only 1 item fill the dropdown right away
                    if (newItems?.length === 1) {
                        item.currentItem = newItems[0];
                        item.currentIndex = 0;
                        autofilled = true;
                    }
                    else {
                        item.currentItem = null;
                        item.currentIndex = null;
                    }
                    return item;
                }
                else if (j > index && reset) {
                    item.disabled = true;
                    item.currentItem = null;
                    item.currentIndex = null;
                    item.items = [];
                    return item;
                }
                else {
                    return null;
                }
            });
        }, () => {
            this.state.dropdownRefs[index].current.focus();
            if (autofilled) {
                this.onChangeDropdown(index, newItems[0]);
            }
        });
    };

    /** general method for change Dropdown */
        //TODO Brand Case Change API Methods generic*/
    onChangeDropdown = async (index, selectedOption) => {
        if (index === 0 && this.state.searchDescription !== null) {
            await this.promisedSetState({searchDescription: null});
            this.props.setQueryParams({ean: null, part: null, technology: null});
        }
        const itemIndex = this.state.dropdownFields[index].items.findIndex(element =>
            element === selectedOption
        );
        await this.promiseSetCurrentItem(index, itemIndex, selectedOption);
        const nextIndex = index + 1;
        let nextDropdown = this.state.dropdownFields[nextIndex]?.name;
        if (nextIndex === this.state.dropdownFields.length) {
            nextDropdown = BRAND_DROPDOWNS.LAST;
        }
        // //On Change sets ProgressBar index to first step
        this.props.setProgressBarIndex(PROGRESS_BAR.STEP1);
        this.props.setCompletedIndex(PROGRESS_BAR.STEP1);

        // resets all subsequent dropdowns if predecessor was changed
        // reset synchronously because we need to know which 
        await this.promiseResetSubsequentDropdowns(index);
        // Set Dropdown Fields to State has to be done due bulb form reset
        this.props.setDropdownFieldValue(this.state.dropdownFields);

        let selectedYear = this.getSelectedYear();
        let selectedModelId = this.getSelectedModelId();
        let selectedVariantId = this.getSelectedVariantId();
        let selectedBrandId = this.getSelectedBrandId();

        switch (this.state.dropdownFields[index]?.name) {
            case BRAND_DROPDOWNS.BRAND:
                selectedBrandId = selectedOption.id;
                this.props.setQueryParams({brand: selectedBrandId});
                break;
            case BRAND_DROPDOWNS.MODEL:
                selectedModelId = selectedOption.model_id;
                selectedVariantId = selectedOption.variant_id;
                this.props.setQueryParams({model: selectedModelId});
                this.props.setQueryParams({variant: selectedVariantId});
                break;
            case BRAND_DROPDOWNS.YEAR:
                selectedYear = selectedOption.name;
                this.props.setQueryParams({year: selectedYear});
                break;
            case BRAND_DROPDOWNS.TYPE:
                break;
            default:
                break;
        }

        switch (nextDropdown) {
            case BRAND_DROPDOWNS.BRAND:
                return this.loadBrandData(nextIndex, selectedYear, selectedModelId, selectedVariantId);
            case BRAND_DROPDOWNS.MODEL:
                return this.loadModelData(nextIndex, selectedBrandId, selectedYear);
            case BRAND_DROPDOWNS.YEAR:
                return this.loadYearData(nextIndex, selectedBrandId, selectedModelId, selectedVariantId);
            case BRAND_DROPDOWNS.TYPE:
                return this.loadTypeData(nextIndex, selectedModelId, selectedVariantId, selectedYear);
            default:
                return this.handleChangeType(selectedOption.id, index, itemIndex);
        }
    };

    lastDropdownSet = (resolver) => {
        // Close panel & Progress Bar Step2
        this.props.selectDots(PROGRESS_BAR.STEP2);
        //only reset Query params when not resolvingQuery Params
        !resolver && this.props.setQueryParams({bulb: null});
        // update completed Index
        this.props.setCompletedIndex(PROGRESS_BAR.STEP2);
    };

    /** all subsequent dropdowns are disabled after reseting */
    resetSubSequentDropdowns = (index) => {
        this.setState(state => {
            state.dropdownFields.map((item, j) => {
                if (j > index) {
                    item.disabled = true;
                    item.currentItem = null;
                    item.items = [];
                    return item;
                }
                else {
                    return null;
                }
            });
            return state;
        });
        // When all dropdowns were reseted initialize first dropdown data
        index === -1 && this.initData().then(res => res);
    };

    handleChangeType = (value, index, itemIndex, resolver) => {
        this.props.setLoadingStateClass("loading-positions");
        this.props.setQueryParams({type: value});
        const dropdownIndex = getCurrentDropdownIndex(this.state.dropdownFields, BRAND_DROPDOWNS.TYPE);
        apiService.fetchBulb(this.props.queryParams.lang, this.state.dropdownFields[dropdownIndex].items[itemIndex]?.id)
        .then(resBulb => {
            this.props.setLoadingStateClass("");
            if (resBulb !== null) {
                //this.setFetchParams(this.state.dropdownFields[dropdownIndex].items[itemIndex].car_id, null, null);
                this.props.setQueryParams({car: this.state.dropdownFields[dropdownIndex].items[itemIndex]?.id});
                this.props.setBulbValues(resBulb);
            }
        });
        this.lastDropdownSet(resolver);
    };

    /** Provide Overview when panel closed */

    provideOverview = (searchDescription = "") => {
        if (searchDescription) {
            return searchDescription;
        }
        const {t} = this.props;
        const {dropdownFields} = this.state;
        const dropdownFieldsOrdered = orderBy(dropdownFields, [BRAND_OVERVIEW_ORDER_ATTRIBUTE], [BRAND_OVERVIEW_ORDER_SORT]);

        return dropdownFieldsOrdered.map((element) => {
            /** Model & Type don't have selectLabel when no dropdown was selected */
            const dropdownIndex = getCurrentDropdownIndex(dropdownFields, element.name);
            const defaultLabel = (element.name === BRAND_DROPDOWNS.MODEL || element.name === BRAND_DROPDOWNS.TYPE) ? "" :
                t(dropdownFields[getCurrentDropdownIndex(dropdownFields, element.name)].selectId);

            let item = "";
            if (dropdownFields[dropdownIndex].currentItem === null ||
                dropdownFields[dropdownIndex].currentItem === "") {
                item = defaultLabel;

            }
            else {
                if (element.name === BRAND_DROPDOWNS.BRAND ||
                    element.name === BRAND_DROPDOWNS.YEAR ||
                    element.name === BRAND_DROPDOWNS.TYPE) {
                    item = dropdownFields[dropdownIndex].currentItem.name;
                }
                else if (element.name === BRAND_DROPDOWNS.MODEL) {
                    item = dropdownFields[dropdownIndex].currentItem.label;
                }
                else {
                    console.error("Unknown dropdown type:", element.name);
                }
            }
            return `${item} `;
        }).join(" ");
    };


    render() {
        const {t, activeIndex} = this.props;
        const {dropdownFields, inputFields, searchDescription, showSearchModalMultiSelect} = this.state;

        return (
            <CollapsePanel
                className="row brand-container"
                isExpanded={activeIndex === PROGRESS_BAR.STEP1}
                progressbarIndex={PROGRESS_BAR.STEP1}
                selectedOption={this.provideOverview(searchDescription)}
                title={t("brand.brandTitle")}
                panelBody={
                    <div className="brand-parent">
                        <div className="row">
                            <div className="col-md-6 brand-br">
                                <div className="brand-select">
                                    <h5>{t("brand.findYourVehicle")}</h5>
                                    <DropdownGroup
                                        dropdownFields={dropdownFields}
                                        onChangeDropdown={this.onChangeDropdown}
                                        dropdownRefs={this.state.dropdownRefs}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="brand-search">
                                    <Search
                                      configLang={this.props.queryParams.lang}
                                      inputFields={inputFields}
                                      handleChange={this.handleInputFieldChange}
                                      setLoadingStateClass={this.props.setLoadingStateClass}
                                    />
                                    <InputGroup
                                        inputFields={inputFields}
                                        handleChange={this.handleInputFieldChange}
                                    />
                                </div>
                            </div>
                            {showSearchModalMultiSelect && this.showModal()}
                        </div>
                    </div>
                }
            />
        );
    }
}


const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    kind: getKind(state),
    activeIndex: getProgressBarIndex(state)
});

const mapDispatchToProps = dispatch => ({
    setInputFieldValue: (inputName, inputValue) => dispatch(setInputFieldValue(inputName, inputValue)),
    setInputFieldError: (inputName, hasError) => dispatch(setInputFieldError(inputName, hasError)),
    setQueryParams: (item) => dispatch(setQueryParams(item)),
    setDropdownFieldValue: (items) => dispatch(setDropdownFieldValue(items)),
    setBulbValues: (bulbs) => dispatch(setBulbValues(bulbs)),
    setCompletedIndex: (index) => dispatch(setCompletedIndex(index)),
    setProgressBarIndex: (index) => dispatch(setProgressBarIndex(index)),
    setProducts: items => dispatch(setProducts(items)),
    setLoadingStateClass: (loadingStateClass) => dispatch(setLoadingStateClass(loadingStateClass))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Brand));
