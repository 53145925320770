import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {OverlayTrigger, Popover} from "react-bootstrap";
import shortId from "shortid";
import {useSelector} from "react-redux";

import {OSRAM_MEDIA_BASEURL, SHARE_BUTTON_RESOURCES, SHARE_MAIL_LOGO} from "../../constant";
import {getQueryParams} from "../../redux/selectors";

import {sendEtrackerClickEvent} from "../../util/utilFkt";

const ShareButton = props => {

    /** Import Field Data */
    const queryParams = useSelector(state => getQueryParams(state));
    const fieldDataImport = require(`../../data/${queryParams.configLang}.json`);

    const {t, shareUrl} = props;

    const lineBreak = "%0D%0A";

    const overlay = (
        <Popover id="popover">
            <div className="modal-body-popover-title">{t("app.share")}</div>
            <div className="social-layer">
                <div className="row">
                    {fieldDataImport.shareData.map(element => {
                        let src, srcset, urlToOpen;
                        if (element.imgResource === SHARE_BUTTON_RESOURCES.OSRAM_MEDIA) {
                            src = `${OSRAM_MEDIA_BASEURL}/40/${element.mediaResource}.jpg`;
                            srcset = `${src} 1x,
                                ${OSRAM_MEDIA_BASEURL}/80/${element.mediaResource}.jpg 2x,`;
                            urlToOpen = element.url + shareUrl;
                        }
                        if (element.imgResource === SHARE_BUTTON_RESOURCES.DAM) {
                            const emailSubject = t("product.emailSubject");
                            const emailBody = `${t("product.emailLine1")}${t("product.emailLine2")}${lineBreak}${encodeURIComponent(shareUrl)}${lineBreak}${t("product.emailLine4")}`;
                            src = SHARE_MAIL_LOGO;
                            srcset = "";
                            urlToOpen = `mailto:?subject=${emailSubject}&body=${emailBody}`;
                        }
                        return (
                            <div key={shortId.generate()} className="col-xs-3">
                                <a href={urlToOpen} target="_blank" rel="noopener noreferrer" onClick={()=> sendEtrackerClickEvent("Share Button", element.title)}>
                                    <img className="social-icon" src={src} srcSet={srcset} alt={element.title} title={element.title} />
                                </a>

                                <p className="font-text-small" style={{"fontSize": "11.1px", "lineHeight": "13.25px"}}>{element.title}</p>
                            </div>
                        );
                    })}
                </div>
            </div>

        </Popover>
    );

    return (
        <OverlayTrigger
            overlay={overlay}
            trigger={"focus"}
            placement="bottom"
        >
            <button id="share-popover" type="button" className="icon-link-c-custom" onClick={()=> sendEtrackerClickEvent("Share Menu Button", "click")}>
                <svg className="icon-download"></svg>
                {t("app.share")}</button>
        </OverlayTrigger>
    );
};

ShareButton.propTypes = {
    shareUrl: PropTypes.string.isRequired,
    t: PropTypes.func.isRequired
};

export default withTranslation()(ShareButton);
