import {useState} from "react";
import { fetchByHSN } from "./api";

/**
 *
 * @param setLoadingStateClass
 *  returns a functions which controls the brand.js loader class.
 * @returns {{toggleHSNInputDisabled: boolean, validateHSN: function, validationHSNErrors: boolean, toggleKBAInputDisabled: boolean, validatedHSN: undefined|string}}
 */
export const useValidateHSN = ({setLoadingStateClass}) => {
  const [toggleKBAInputDisabled, setToggleKBAInputDisabled] = useState(true);
  const [toggleHSNInputDisabled, setToggleHSNInputDisabled] = useState(false);
  const [validationHSNErrors, setValidationHSNErrors] = useState(false);
  const [validatedHSN, setValidatedHsn] = useState();

  const validateHSN = (argsHSN, configLang) => {
    // Do not start validation until 4 digits are entered.
    if (argsHSN.length !== 4) {
      setToggleKBAInputDisabled(true);
      return;
    }

    setToggleHSNInputDisabled(true);
    setToggleKBAInputDisabled(true);
    setLoadingStateClass('loading-brand');
    setValidationHSNErrors(false);

    fetchByHSN(argsHSN, configLang).then((response) => {
      if (response && response?.result === true) {
        setToggleKBAInputDisabled(false);
        setValidatedHsn(argsHSN);
      } else if (response && response?.result === false) {
        setValidationHSNErrors(true);
      }
    }).catch(e => {
      console.error(e, 'validateHSN');
      setValidationHSNErrors(true);
    }).finally(()=> {
      setLoadingStateClass('');
      setToggleHSNInputDisabled(false);
    })
  };

  return {
    toggleKBAInputDisabled,
    toggleHSNInputDisabled,
    validationHSNErrors,
    validatedHSN,
    validateHSN
  };
};