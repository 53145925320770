export const bulbIcons = new Map([
    ["2668","icon-bulp headlight-bulb-low-beam"],
    ["2669","icon-bulp headlight-bulb-high-beam"],
    ["2670","icon-bulp parking-light-bulb"],
    ["2671","icon-bulp headlight-bulb-high-beam-and-low-beam"],
    ["2672","icon-bulp turn-signal-light-bulb-front"],
    ["2673","icon-bulp side-marker-light-bulb-front"],
    ["2674","icon-bulp turn-signal-light-bulb-rear"],
    ["2675","icon-bulp tail-light-bulb"],
    ["2676","icon-bulp center-high-mount-stop-light-bulb"],
    ["2677","icon-bulp back-up-light-bulb"],
    ["2678","icon-bulp fog-light-bulb-rear"],
    ["2679","icon-bulp license-plate-light-bulb"],
    ["2680","icon-bulp brake-light-bulb"],
    ["2681","icon-bulp side-marker-light-bulb-front"],
    ["2682","icon-bulp dome-light-bulb"],
    ["2683","icon-bulp interior-door-light-bulb"],
    ["2684","icon-bulp vanity-mirror-light-bulb"],
    ["2685","icon-bulp trunk-or-cargo-area-light"],
    ["2686","icon-bulp vanity-mirror-light-bulb"],
    ["3015","icon-bulp courtesy-light-bulb"],
    ["3016","icon-bulp map-light-bulb"],
    ["3017","icon-bulp glove-box-light-bulb"],
    ["3018","icon-bulp engine-compartment-light-bulb"],
    ["3066","icon-bulp headlight-bulb-high-beam"],
    ["3073","icon-bulp stepwell-light-bulb"],
    ["4360","icon-bulp daytime-running-light-bulb"],
    ["4377","icon-bulp side-marker-light-bulb-front"],
    ["4799","icon-bulp cornering-light-bulb"],
]);

export const bulbPosition  = {
    FORWARD: "409",
    EXTERIOR: "410",
    INTERIOR: "411"
}



