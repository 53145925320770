import React from "react";
import PropTypes from "prop-types";
import queryString from "query-string";

import BuyButton from "../../composition/BuyButton/BuyButton";
import ShareButton from "./ShareButton";
import {CONFIG_LANG_KEY} from "../../constant";
import ContactIframeButton from "./ContactIframeButton";

const ActionButtons = props => {
    const {selectedProductDetails, queryParams, contactIframeUrl} = props;
    /** Import Field Data */
    const { actionButtons } = require(`../../data/${queryParams.configLang}.json`);
    /** delete ConfigLang Key from Share URL */
    const queryParamsUrl = {...queryParams};
    delete queryParamsUrl[CONFIG_LANG_KEY];
    const shareUrl = `${window.location.href}?${queryString.stringify(queryParamsUrl, {skipNull: true})}`;
    return (
        <>
            {/* Buy Modal */}
            {actionButtons.share && <ShareButton shareUrl={shareUrl} />}
            {actionButtons.contact && <ContactIframeButton externalURL={contactIframeUrl} />}
            {actionButtons.buy && selectedProductDetails &&
                <BuyButton
                  configLang={queryParams.lang}
                  selectedProductDetails={selectedProductDetails}
                />
            }
        </>
    );
}

ActionButtons.propTypes = {
    selectedProductDetails: PropTypes.object,
    orderNr: PropTypes.string,
    queryParams: PropTypes.object.isRequired,
    contactIframeUrl: PropTypes.string
};

export default ActionButtons;
