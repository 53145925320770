import React from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import shortId from "shortid";

import {setKind, setQueryParams} from "../redux/actions";
import {getKind, getQueryParams} from "../redux/selectors";

import { sendEtrackerKindEvent } from "../util/utilFkt";

const Vehicle = (props) => {
    /** Import Field Data */
    const queryParams = useSelector(state => getQueryParams(state));
    const fieldDataImport = require(`../data/${queryParams.configLang}.json`);

    const dispatch = useDispatch();
    const kind = useSelector(state => getKind(state));

    const clickKind = (kind) => {
        dispatch(setKind(kind));
        dispatch(setQueryParams({kind}));
    };

    if (!fieldDataImport.vehicleFields) {
        return null;
    }

    const {t} = props;
    return (
        <div className="vehicle-part">
            <h4>{t("vehicle.title")}</h4>
            <div className="row">
                {fieldDataImport.vehicleFields.map(vehicle => (
                    <div className="col-sm-4" key={shortId.generate()}>
                        <div className="vehicle">
                            <img
                                src={process.env.PUBLIC_URL + vehicle.imageUrl}
                                alt={t(vehicle.imageAltId)}
                                onClick={() => kind !== vehicle.kind && clickKind(vehicle.kind)}
                            />
                            <button onClick={() => {
                                if (kind !== vehicle.kind) {
                                    sendEtrackerKindEvent(vehicle.kind);
                                    clickKind(vehicle.kind);
                                }
                            }}>
                                {t(vehicle.buttonLabelId)}
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div id="gvlrgdisclaimer" className="vehicle-disclamer">
                <h5>{t("app.disclaimerheading")}</h5>

                <p>{t("app.disclaimertext")}</p>
            </div>
        </div>
    );
};

Vehicle.propTypes = {
    t: PropTypes.func.isRequired
}

export default withTranslation()(Vehicle);
