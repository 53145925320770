import React from "react";
import {withTranslation} from "react-i18next";
import PropTypes from "prop-types";
import {OverlayTrigger, Popover} from "react-bootstrap";
import debounce from "lodash/debounce";

const InputItem = (props) => {
    const {
        t, titleId, subtitleId, withInfoImage, imageUrl,
        handleChange, inputValue, errorMessage, inputName
    } = props;
    const overlay = (
        <Popover id="popover">
            <img
                className="popup"
                src={process.env.PUBLIC_URL + imageUrl}
                alt="info"
            />
        </Popover>
    );
    const inputRef = React.createRef();
    const debounceTimeMs = 1000;
    const delayedHandle = debounce((ref) => handleChange(ref), debounceTimeMs);
    return (
        <div>
            <h5>{t(titleId)}</h5>
            {withInfoImage ?
                <h6 className="info">
                    {t(subtitleId)}
                    <OverlayTrigger
                        overlay={overlay}
                        trigger={["hover", "focus"]}
                        placement="top"
                    >
                        <span className="info ms-icon_help" />
                    </OverlayTrigger>
                </h6> :
                <h6>{t(subtitleId)}</h6>
            }

            <div className="brand-search-input input-group">
                <input
                    ref={inputRef}
                    type="text"
                    name={inputName}
                    defaultValue={inputValue}
                    className="form-control"
                    onKeyUp={(e) => {
                        delayedHandle(inputRef.current);
                    }}
                />
                <span onClick={() => {
                    handleChange(inputRef.current);
                }} className="input-group-addon ms-icon_loupe" />
            </div>
            {errorMessage &&
            <p className="notfound">{t(errorMessage)}</p>
            }
        </div>
    );
};

InputItem.propTypes = {
    inputName: PropTypes.string.isRequired,
    titleId: PropTypes.string,
    subtitleId: PropTypes.string,
    withInfoImage: PropTypes.bool,
    imageUrl: PropTypes.string,
    handleChange: PropTypes.func,
    inputValue: PropTypes.string,
    errorMessage: PropTypes.string,
    t: PropTypes.func.isRequired
};

export default withTranslation()(InputItem);
