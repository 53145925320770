import React, {Component} from "react";
import PropTypes from "prop-types";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";

import {apiService} from "../service/Apiservice";
import {getProgressBarIndex, getQueryParams, getTechnologies} from "../redux/selectors";
import {setCompletedIndex, setProducts, setQueryParams, setLoadingStateClass} from "../redux/actions";
import {PROGRESS_BAR} from "../constant";
import CollapsePanel from "./CollapsePanel/CollapsePanel";
import {technologyShape} from "../shapes";

import { sendEtrackerClickEventAndValue } from "../util/utilFkt";

class Note extends Component {
    static propTypes = {
        t: PropTypes.func.isRequired,
        selectDots: PropTypes.func.isRequired,
        setProducts: PropTypes.func.isRequired,
        setQueryParams: PropTypes.func.isRequired,
        setCompletedIndex: PropTypes.func.isRequired,
        activeIndex: PropTypes.number,
        queryParams: PropTypes.object.isRequired,
        technologies: PropTypes.arrayOf(technologyShape).isRequired,
        setLoadingStateClass: PropTypes.func.isRequired
    };

    componentDidMount() {
        // Resolve Query Params from URL
        const {queryParams, technologies} = this.props;
        queryParams.technology && this.selectNoteWithValue(queryParams.technology, true);

        // Sets once Technology if only one value is available
        let selected = queryParams.technology;
        if (!selected && technologies && technologies.length === 1) {
            this.selectNoteWithValue(technologies[0].technology_id);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const {queryParams, technologies} = this.props;
        let selected = queryParams.technology;

        // Checks if not selected and technology prop changed
        if (!selected && prevProps.technologies !== technologies && technologies.length === 1) {
            this.selectNoteWithValue(technologies[0].technology_id);
        }
    }

    /** Choose Technology Component */
    selectNoteWithValue = (selectedNoteValue, resolver) => {
        this.props.setQueryParams({technology: selectedNoteValue});
        this.props.setLoadingStateClass("loading-pillar");
        apiService.fetchPillar(this.props.queryParams.lang, this.props.queryParams.car, this.props.queryParams.bulb, selectedNoteValue)
        .then(resPillar => {
            this.props.setLoadingStateClass("");
            if (resPillar !== null) {
                this.props.setProducts(resPillar);
                // Only display ECE in "Your matching product" if all products have the same.
                if (Array.isArray(resPillar) && resPillar.length > 0 && typeof resPillar[0].ece === 'string') {
                    let eceCounts = {};
            
                    resPillar.forEach(pillarProduct => {
                        if (typeof pillarProduct.ece === 'string' && pillarProduct.ece.trim().length > 0) {
                            if (typeof eceCounts[pillarProduct.ece] === 'number') {
                                eceCounts[pillarProduct.ece] = eceCounts[pillarProduct.ece] + 1;
                            } else {
                                eceCounts[pillarProduct.ece] = 1;
                            }
                        }
                    });
                    if (Object.keys(eceCounts).length > 0) {
                        const mostUsedEce = Object.keys(eceCounts).reduce((a, b) => eceCounts[a] > eceCounts[b] ? a : b);
                        this.props.setQueryParams({part: mostUsedEce});
                    } else {
                        this.props.setQueryParams({part: null});
                    }
                } else {
                    this.props.setQueryParams({part: null});
                }
            }
        });
        //Reset ean (product) Query Param only if not resolving
        !resolver && this.props.setQueryParams({ean: null});
        this.props.selectDots(PROGRESS_BAR.STEP4);
        this.props.setCompletedIndex(PROGRESS_BAR.STEP4);
    };

    render() {
        const {t, queryParams, technologies, activeIndex} = this.props;
        let selectedTech = technologies.find(tech => tech?.technology_id === queryParams?.technology);
        let selected = selectedTech?.technology_id;
        let selectedName = selectedTech?.tech_name;
        return (
            <CollapsePanel
                className={"row technologies"}
                isExpanded={activeIndex === PROGRESS_BAR.STEP3}
                progressbarIndex={PROGRESS_BAR.STEP3}
                selectedOption={selectedName}
                title={t("note.chooseTechnology")}
                panelBody={
                    <div className="note-panel technology-panel">
                        <div className="note-panel-content">
                            <ul>
                                {technologies.map((technology) =>
                                    <li key={technology.technology_id} className={"noteList technology-card" + (selected === technology.technology_id ? ' selected' : '')}>
                                        <p onClick={() => {
                                            sendEtrackerClickEventAndValue('Technology', technology.tech_name);
                                            this.selectNoteWithValue(technology.technology_id);
                                        }}>
                                            {technology.tech_name}
                                        </p>
                                    </li>
                                )}
                            </ul>
                        </div>
                    </div>
                }
            />
        );
    }
}

const mapStateToProps = state => ({
    queryParams: getQueryParams(state),
    technologies: getTechnologies(state),
    activeIndex: getProgressBarIndex(state)
});
const mapDispatchToProps = dispatch => ({
    setQueryParams: (item) => dispatch(setQueryParams(item)),
    setProducts: products => dispatch(setProducts(products)),
    setCompletedIndex: (index) => dispatch(setCompletedIndex(index)),
    setLoadingStateClass: (loadingStateClass) => dispatch(setLoadingStateClass(loadingStateClass))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Note));
