export const getInputFieldValues = state => state.data.kind.brand.inputFieldValues;
export const getInputFieldErrors = state => state.data.kind.brand.inputFieldErrors;
export const getQueryParams = state => state.queryParams;
export const getBulbs = state => state.data.kind.brand.bulb.bulbs;
export const getTechnologies = state => state.data.kind.brand.bulb.technology?.technologies;
export const getKind = state => state.data.kind.kindId;
export const getProgressBarIndex = state => state.progressBarIndex;
export const getCompletedIndex = state => state.completedIndex;
export const getProducts = state => state.data.kind.brand.bulb.technology?.product?.products;
export const getProductDescription = state => state.data.kind.brand.bulb.technology?.product?.description;
export const getLoadingStateClass = state => state.loadingStateClass
