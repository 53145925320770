import React, {useState} from "react";
import PropTypes from "prop-types";
import {Panel} from "react-bootstrap";
import {withTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

import {setProgressBarIndex} from "../../redux/actions";

import { sendEtrackerChangeEvent } from "../../util/utilFkt";

const CollapsePanel = props => {
    const [expanded, setExpanded] = useState(props.isExpanded);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setExpanded(props.isExpanded);
    }, [props.isExpanded]);

    const handleToggle = expanded => {
        if (!expanded) {
            sendEtrackerChangeEvent(props.progressbarIndex);
            dispatch(setProgressBarIndex(props.progressbarIndex))
        }
        setExpanded(!expanded)
    };

    return (
        <Panel
            id="collapsible-panel"
            expanded={expanded}
            className={"collapsible-panel " + props.className}
            onToggle={() => handleToggle(expanded)}
        >
            <Panel.Heading>
                <Panel.Title componentClass="h4">
                    {props.title}
                </Panel.Title>
                <div
                    onClick={() => handleToggle(expanded)}
                    className={(expanded) ? " accordion-icon ms-icon_minus" : "accordion-icon ms-icon_edit"}
                />
                {(!expanded && props.selectedOption) &&
                <p className="collapsible-panel-selected-option">
                    {`${props.selectedOption} `}
                </p>
                }
            </Panel.Heading>
            <Panel.Collapse className="col-xs-12">
                <Panel.Body>
                    {props.panelBody}
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    );
}

CollapsePanel.propTypes = {
    panelBody: PropTypes.node.isRequired,
    isExpanded: PropTypes.bool,
    className: PropTypes.string,
    title: PropTypes.string,
    selectedOption: PropTypes.string,
    progressbarIndex: PropTypes.number.isRequired,
    t: PropTypes.func.isRequired
}

export default withTranslation()(CollapsePanel);
