import React, {Component} from "react";
import PropTypes from "prop-types";

class ErrorReport extends Component {
    static propTypes = {
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
        this.state = {
            hasError: false
        };
    }

    componentDidCatch(error, info) {
        this.setState({hasError: true});
        console.log("error", error);
        console.log("info", info);
    }

    render() {
        const {children} = this.props;
        const {hasError} = this.state;
        if (hasError) {
            return <h2>Something went wrong</h2>;
        }

        return children;
    }
}

export default ErrorReport;
