import PropTypes from "prop-types";

export const benefitShape = PropTypes.shape({
    icon: PropTypes.string,
    iconText: PropTypes.string,
    highlight: PropTypes.bool,
    featureText: PropTypes.string,
    featureValue: PropTypes.number,
    standardText: PropTypes.string,
    standardValue: PropTypes.number
});

export const descriptionShape = PropTypes.shape({
    thumb_img: PropTypes.arrayOf(PropTypes.string),
    main_img: PropTypes.arrayOf(PropTypes.string),
    benefits: PropTypes.arrayOf(benefitShape),
    buy_now_links: PropTypes.arrayOf(PropTypes.string),
    marketing_text: PropTypes.string,
    headline: PropTypes.string,
    oonum: PropTypes.string,
    oece: PropTypes.string,
    linfo: PropTypes.string,
    zmpnr: PropTypes.string,
    offroadinfotext: PropTypes.string,
    ledinfotext: PropTypes.string
});

export const productShape = PropTypes.shape({
    linecard_id: PropTypes.string,
    ean: PropTypes.string,
    usp: PropTypes.string,
    use_name: PropTypes.string,
    color: PropTypes.string,
    pillar: PropTypes.string,
    prio: PropTypes.string
});

export const technologyShape = PropTypes.shape({
    name: PropTypes.string
});

export const bulbShape = PropTypes.shape({
    use_id: PropTypes.string,
    use_name: PropTypes.string,
    pos_id: PropTypes.string,
    pos_name: PropTypes.string
});
